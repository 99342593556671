import { Injectable } from '@angular/core';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

import { LinkService } from './link.service';
import { ContextService } from './context.service';
import { RegionDataService } from './region-data.service';
import { CentralDataService } from './central-data.service';
import { IAccount, IAccountDepartment, IRights, parseAccountData } from './auth.service';

import { FullDepartment, ManningContract, ManningPerson, calculateManningContracts } from './../commontypes/manning';
import { UIService } from './ui.service';
import dayjs from 'dayjs';

import { gql } from 'apollo-angular';
import { LoggingService } from './logging.service';
import { createDeptExistsFn, createSubDeptGetFn } from 'src/commontypes/util';

@Injectable({
  providedIn: 'root',
})
export class ManningDataService {
  private departments: { [hotelId: string]: FullDepartment[] } = {};
  private roles: { id; name; label; jobBand }[] = [];

  constructor(
    private linkService: LinkService,
    private regionData: RegionDataService,
    private uiService: UIService,
    private contextService: ContextService,
    private centralData: CentralDataService,
    private log: LoggingService
  ) {}

  public getAccount(accountId: string): Observable<IAccount> {
    return this.linkService
      .GQLCentral()
      .query<{ allAccounts: any[] }>({
        query: gql`
                query getAccount($accountId:ID!) {
                  allAccounts(id: $accountId) {
                    ...${accountfragment.name}
                  }
                }
                ${accountfragment.definition}
          `,
        variables: {
          accountId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map(({ data }) => data.allAccounts && parseAccountData(data.allAccounts[0])));
  }

  public getAccountByEmail(email: string): Observable<IAccount> {
    return this.linkService
      .GQLCentral()
      .query({
        query: gql`
            query getAccountByEmail($email:String!) {
              allAccounts(email: $email) {
                ...${accountfragment.name}
              }
            }
            ${accountfragment.definition}
          `,
        variables: {
          email,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.allAccounts && data.allAccounts[0] && parseAccountData(data.allAccounts[0]);
        })
      );
  }

  private createDepartments(hotel, baseDepartments: Array<{ class; name; label; defaultOrder }>) {
    const adl: IAccountDepartment[] = [];
    const { id: hotelId, holidexCode, departmentConfig } = hotel;
    const departmentExists = createDeptExistsFn(departmentConfig);
    const getSubDeptConfig = createSubDeptGetFn(hotel.departmentConfig);

    baseDepartments.forEach((d: any) => {
      if (d.name == 'RESTAURANTS') {
        // add one department for each outlet
        hotel.restaurants.forEach((o) => {
          adl.push({
            hotelId,
            holidexCode,
            name: d.name,
            defaultOrder: d.defaultOrder,
            label: o.name + ' (outlet)',
            outletIndex: o.index,
            outletType: 'RESTAURANT',
          });
        });
      } else if (d.name == 'RETAIL_OUTLETS') {
        // add one department for each outlet
        hotel.retailOutlets.forEach((o) => {
          adl.push({
            hotelId,
            holidexCode,
            name: d.name,
            defaultOrder: d.defaultOrder,
            label: o.name + ' (outlet)',
            outletIndex: o.index,
            outletType: 'RETAIL',
          });
        });
      } else if (d.name == 'BARS') {
        // add one department for each outlet
        hotel.bars.forEach((o) => {
          adl.push({
            hotelId,
            holidexCode,
            name: d.name,
            defaultOrder: d.defaultOrder,
            label: o.name + ' (outlet)',
            outletIndex: o.index,
            outletType: 'BAR',
          });
        });
      } else if (d.name === 'KITCHEN') {
        adl.push({
          ...d,
          hotelId,
          holidexCode,
          label: 'Main Kitchen',
        });
        hotel.restaurants
          .filter((o) => o.kitchen)
          .forEach((o) => {
            adl.push({
              hotelId,
              holidexCode,
              name: d.name,
              defaultOrder: d.defaultOrder,
              label: o.name + ' - Kitchen (outlet)',
              outletIndex: o.index,
              outletType: 'RESTAURANT',
            });
          });
        hotel.bars
          .filter((o) => o.kitchen)
          .forEach((o) => {
            adl.push({
              hotelId,
              holidexCode,
              name: d.name,
              defaultOrder: d.defaultOrder,
              label: o.name + ' - Kitchen (outlet)',
              outletIndex: o.index,
              outletType: 'BAR',
            });
          });
      } else if (d.name === 'STEWARDING') {
        adl.push({
          ...d,
          hotelId,
          holidexCode,
          label: 'Main Stewarding',
        });
        hotel.restaurants
          .filter((o) => o.stewarding)
          .forEach((o) => {
            adl.push({
              hotelId,
              holidexCode,
              name: d.name,
              defaultOrder: d.defaultOrder,
              label: o.name + ' - Stewarding (outlet)',
              outletIndex: o.index,
              outletType: 'RESTAURANT',
            });
          });
        hotel.bars
          .filter((o) => o.stewarding)
          .forEach((o) => {
            adl.push({
              hotelId,
              holidexCode,
              name: d.name,
              defaultOrder: d.defaultOrder,
              label: o.name + ' - Stewarding (outlet)',
              outletIndex: o.index,
              outletType: 'BAR',
            });
          });
      } else if (d.name !== 'UNKNOWN') {
        if (departmentExists(d.name)) {
          adl.push({ hotelId, holidexCode, ...d });
          const { kitchen, stewarding } = getSubDeptConfig(d.name);
          if (kitchen) {
            adl.push({ hotelId, holidexCode, ...d, label: d.label + ' - Kitchen', subDeptName: 'KITCHEN' });
          }
          if (stewarding) {
            adl.push({ hotelId, holidexCode, ...d, label: d.label + ' - Stewarding', subDeptName: 'STEWARDING' });
          }
        }
      }
    });
    return adl;
  }

  /**
   * Gets a list of all functional departments including outlets but _not_ associated outlet services
   * @param hotelIdin
   * @returns
   */
  getRightsDepartmentsForHotel(hotelIdin: string = null): Observable<IAccountDepartment[]> {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return forkJoin({
          baseDepartments: this.centralData.getDepartments().pipe(first()),
          hotel: this.regionData.getHotelWithDeptConfig(hotelId).pipe(first()),
        }).pipe(
          map(({ baseDepartments, hotel }) => {
            return this.createDepartments(hotel, baseDepartments);
          })
        );
      })
    );
  }

  getRightsDepartmentsForCluster(hotelIdin: string): Observable<IAccountDepartment[]> {
    return this.linkService.awaitCurrentHotel(hotelIdin).pipe(
      mergeMap((hotelId: string) => {
        return forkJoin({
          baseDepartments: this.centralData.getDepartments().pipe(first()),
          hotels: this.regionData.getClusterHotelsWithDeptConfig(hotelId).pipe(first()),
        }).pipe(
          map(({ baseDepartments, hotels }) => {
            return hotels.reduce((prev, hotel) => {
              prev.push(...this.createDepartments(hotel, baseDepartments));
              return prev;
            }, [] as IAccountDepartment[]);
          })
        );
      })
    );
  }

  public getCheckInIdPerson(checkInId: string, hotelIdIn?: string) {
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        return this.linkService.GQLRegion().pipe(
          mergeMap((link) =>
            link
              .query<{
                getPersonByCheckIn: { id; checkInId; personnelNo; status; hotel: { id; holidexCode }; contracts; currentContract };
              }>({
                query: gql`
                  query getPersonByCheckIn($hotelId:ID!, $checkInId: String!) {
                    getPersonByCheckIn(checkInId: $checkInId, hotelId: $hotelId) {
                      id
                      checkInId
                      personnelNo
                      status
                      hotel { id, holidexCode }
                      contracts {
                        ...${personcontractfragment.name}
                      }
                      currentContract {
                        ...${personcontractfragment.name}
                      }
                    }
                  }
                  ${personcontractfragment.definition}
            `,
                variables: {
                  hotelId,
                  checkInId,
                },
                fetchPolicy: 'network-only',
              })
              .pipe(map(({ data }) => data?.getPersonByCheckIn))
          )
        );
      })
    );
  }

  public getPersons(hotelIdIn: string, includeCluster = false, includeHolidays = false): Observable<ManningPerson[]> {
    return this.linkService.awaitCurrentHotel(hotelIdIn).pipe(
      mergeMap((hotelId) => {
        return forkJoin({
          persons: includeHolidays
            ? this.getPersonsBasicWithHoliday(hotelId, includeCluster).pipe(first())
            : this.getPersonsBasic(hotelId, includeCluster).pipe(first()),
          otherHotelIds: this.regionData.getCluster(hotelId).pipe(
            first(),
            map((cluster) => {
              return cluster?.hotelIds.filter((id) => hotelId !== id) || [];
            })
          ),
          roles: this.regionData.getRoles(true).pipe(first()),
        }).pipe(
          mergeMap(({ otherHotelIds, ...rest }) => {
            if (otherHotelIds.length < 1) {
              return this.regionData.getFullDepartmentsForHotel(hotelId).pipe(
                first(),
                map((departments) => ({ ...rest, departments }))
              );
            }
            return this.regionData.getFullDepartmentsForCluster().pipe(
              first(),
              map((departments) => ({ ...rest, departments }))
            );
          }),
          map(({ persons, roles, departments }) => {
            const mappedDepartments = departments.reduce((prev, dept) => {
              const depts = prev[dept.hotelId] || [];
              depts.push(dept);
              prev[dept.hotelId] = depts;
              return prev;
            }, {} as { [hotelId: string]: FullDepartment[] });
            this.departments = mappedDepartments;
            this.roles = roles;
            return persons.map((p) => this.processPerson(p));
          }),
          catchError((err) => {
            this.uiService.error('Failed to load employees.', 'A network or server error has occurred - please try again later.');
            return of(null);
          })
        );
      })
    );
  }

  private getPersonStatusText = (status, contracts, leavingDate, contractsEndDate) => {
    if (status === 'TERMINATED') {
      return `Terminated (${dayjs(leavingDate).format('YYYY-MM-DD')})`;
    } else if (status === 'LEAVER') {
      if (dayjs().isAfter(leavingDate)) return `Terminated (${dayjs(leavingDate).format('YYYY-MM-DD')})`;
      else return `Active (Leaving ${dayjs(leavingDate).format('YYYY-MM-DD')})`;
    } else if (contractsEndDate && dayjs().isAfter(contractsEndDate, 'day')) {
      return `Active (Contracts ended ${dayjs(contractsEndDate).format('YYYY-MM-DD')})`;
    } else if (contractsEndDate && dayjs().diff(contractsEndDate, 'M') >= -3) {
      return `Active (Contracts expire ${dayjs(contractsEndDate).format('YYYY-MM-DD')})`;
    } else if (contracts.some(({ start }) => dayjs().isAfter(start))) return 'Active';
    else return 'Active (Future Starter)';
  };

  private processPerson(p): ManningPerson {
    const contracts = p.contracts.map((c) => this.processContract(c));

    let contractsEndDate = p.contracts?.length ? p.contracts[p.contracts.length - 1]?.end : null;

    if (p.leavingDate && (p.status === 'LEAVER' || p.status === 'TERMINATED')) {
      contractsEndDate = !contractsEndDate
        ? new Date(p.leavingDate)
        : dayjs(contractsEndDate).isBefore(p.leavingDate)
        ? new Date(contractsEndDate)
        : new Date(p.leavingDate);
    }

    const annualH = p.annualHolidaysTaken?.[0];
    const publicH = p.publicHolidaysTaken?.[0];

    const holidayStart = annualH?.startDate || publicH?.startDate || `${dayjs().year()}-01-01`;
    const holidayType = annualH?.statsType?.toLowerCase() || publicH?.statsType?.toLowerCase();

    let lastHoliday = annualH?.lastDay;
    if (annualH?.lastDay && publicH?.lastDay) {
      lastHoliday = dayjs(publicH.lastDay).isAfter(annualH.lastDay) ? publicH.lastDay : annualH.lastDay;
    } else if (publicH?.lastDay) {
      lastHoliday = publicH?.lastDay;
    }

    let holidaysUpdatedAt;
    if (annualH?.updatedAt && publicH?.updatedAt) {
      holidaysUpdatedAt = new Date(dayjs(publicH.updatedAt).isAfter(annualH.updatedAt) ? publicH.updatedAt : annualH.updatedAt);
    } else if (publicH?.lastDay) {
      holidaysUpdatedAt = new Date(publicH?.lastDay);
    } else if (annualH?.updatedAt) {
      holidaysUpdatedAt = new Date(annualH?.lastDay);
    }

    const per: ManningPerson = {
      hotelId: p.hotelId || p.hotel?.id,
      holidexCode: p.holidexCode || p.hotel?.holidexCode,
      accountId: p.accountId,
      hasAccount: !!p.accountId,
      accountEnabled: p.account?.enabled ? 'Yes' : 'No',
      accountStatus: p.accountId ? 'Yes' : 'No',
      id: p.id,
      firstName: p.firstName,
      lastName: p.lastName,
      personnelNo: p.personnelNo,
      holidayDays: p.holidayDays,
      holidayHours: p.holidayHours,
      checkInId: p.checkInId,
      publicHolidayDays: p.publicHolidayDays,
      publicHolidayHours: p.publicHolidayHours,
      sickDays: p.sickDays,
      leaving: p.status === 'LEAVER' || p.status === 'TERMINATED',
      statusText: this.getPersonStatusText(p.status, p.contracts, p.leavingDate, contractsEndDate),
      contracts: contracts,
      allContractsValid: true,
      leaveStartDate: p.leaveStartDate ? new Date(p.leaveStartDate) : null,
      leaveEndDate: p.leaveEndDate ? new Date(p.leaveEndDate) : null,
      leavingDate: p.leavingDate ? new Date(p.leavingDate) : null,
      contractsEndDate: contractsEndDate,
      agency: p.agency,
      holidayStart,
      holidayType,
      holidaysUpdatedAt,
      lastHoliday,
      annualHolidaysTaken: (holidayType === 'days' ? annualH?.days : annualH?.hours) || 0,
      publicHolidaysTaken: (holidayType === 'days' ? publicH?.days : publicH?.hours) || 0,
    };
    if (p.personnelNo) {
      per.personnelNo = p.personnelNo;
    }
    return calculateManningContracts(per);
  }

  private processContract(inputContract): ManningContract {
    if (!inputContract) return null;
    return {
      id: inputContract.id,
      hotelId: inputContract.hotelId || this.linkService.getCurrentHotelId(),
      role: this.roles.find((r) => r.name === inputContract.roleName || (inputContract.role ? inputContract.role.name === r.name : false)),
      jobBand: inputContract.jobBand,
      payRate: typeof inputContract.payRate === 'number' ? inputContract.payRate : null,
      department: this.matchFullDepartment(inputContract.department),
      otherDepartments: inputContract.otherDepartments?.map((d) => this.matchFullDepartment(d)) ?? [],
      start: new Date(inputContract.start),
      end: inputContract.end ? new Date(inputContract.end) : null,
      contractedHours: inputContract.contractedHours,
      workDays: inputContract.workDays,
      title: inputContract.title,
      isValid: true,
      isActive: false,
      casual: !!inputContract.casual,
    };
  }

  private matchFullDepartment(inputDept: {
    hotelId: string;
    name?: string;
    departmentName?: string;
    subDeptName?: string;
    outletIndex?: number;
    outletType?: string;
  }) {
    const name = inputDept.name || inputDept.departmentName;
    const subDeptName = inputDept.subDeptName;
    return this.departments[inputDept.hotelId]?.find(
      (dept) =>
        dept.name == name &&
        (subDeptName ? subDeptName == dept.subDeptName : !dept.subDeptName) &&
        (inputDept.outletType ? inputDept.outletType == dept.outletType && inputDept.outletIndex == dept.outletIndex : !dept.outletType)
    );
  }

  private getPersonsBasic(hotelId: string, includeCluster: boolean) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                  query getPersons($hotelId:ID!, $includeCluster: Boolean) {
                    getPersons(hotelId:$hotelId, includeCluster: $includeCluster) {
                      ...${basicpersonfragment.name}
                    }
                  }
                  ${basicpersonfragment.definition}
            `,
            variables: {
              hotelId,
              includeCluster,
            },
          })
          .pipe(
            map(({ data }: any) => {
              return data.getPersons?.filter(({ status }) => status !== 'PLANNING_ONLY');
            })
          )
      )
    );
  }

  private getPersonsBasicWithHoliday(hotelId: string, includeCluster: boolean) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query({
            query: gql`
                  query getPersonsWithHoliday($hotelId:ID!, $includeCluster: Boolean) {
                    getPersons(hotelId:$hotelId, includeCluster: $includeCluster) {
                      ...${basicpersonfragment.name}
                      ...${personholidayfragment.name}
                    }
                  }
                  ${basicpersonfragment.definition}
                  ${personholidayfragment.definition}
                  `,
            variables: {
              hotelId,
              includeCluster,
            },
          })
          .pipe(
            map(({ data }: any) => {
              return data.getPersons?.filter(({ status }) => status !== 'PLANNING_ONLY');
            })
          )
      )
    );
  }

  public getPersonFutureShifts(personId: string) {
    this.log.debug({
      personId,
      calendarDate: dayjs().format('YYYY-MM-DD'),
    });
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ getPerson?: { shifts } }>({
            query: gql`
              query getPerson($personId: ID!, $calendarDate: CalendarDate!) {
                getPerson(personId: $personId) {
                  shifts(fromDay: $calendarDate) {
                    start
                    startDay
                    end
                    hotel {
                      id
                      holidexCode
                    }
                    departmentName
                    subDeptName
                    outletIndex
                    outletType
                  }
                }
              }
            `,
            variables: {
              personId,
              calendarDate: dayjs().format('YYYY-MM-DD'),
            },
          })
          .pipe(
            map(({ data }) => {
              return data.getPerson?.shifts;
            })
          )
      )
    );
  }

  public getPersonStatus(personId: string) {
    return this.linkService.GQLRegion().pipe(
      mergeMap((link) =>
        link
          .query<{ getPerson?: { status; leavingDate; contractsEndDate } }>({
            query: gql`
              query getPerson($personId: ID!) {
                getPerson(personId: $personId) {
                  status
                  leavingDate
                  contractsEndDate
                }
              }
            `,
            variables: {
              personId,
            },
          })
          .pipe(
            map(({ data }) => {
              return data.getPerson;
            })
          )
      )
    );
  }

  public deletePerson(personId: string, confirm: boolean) {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation deletePerson($personId: ID!, $confirm: Boolean!) {
                deletePerson(personId: $personId, confirm: $confirm)
              }
            `,
            variables: {
              personId,
              confirm,
            },
          })
          .pipe(map(({ data }: any) => data.deletePerson as string))
      )
    );
  }

  public saveAccount(account: IAccount, { personId, hotelId }) {
    const regionId = this.contextService.getCurrentRegionId();
    return account.isAdminRead || account.isAdminWrite // we can't edit admin accounts so we just update the account id
      ? this.updatePersonAccount(personId, account.id).pipe(
          first(),
          map((acc) => acc.accountId)
        )
      : this.upsertAccount(account, { personId, hotelId, regionId }).pipe(
          first(),
          map((acc) => acc.id)
        );
  }

  public savePerson({ leaving, ...inputPerson }, removeAccount, account?: IAccount, skipAccountChanges = false): Observable<ManningPerson> {
    const hotelId = inputPerson.hotelId;
    if (!leaving) {
      inputPerson.status = 'ACTIVE';
    } else {
      if (!inputPerson.leavingDate || dayjs().isAfter(inputPerson.leavingDate)) {
        inputPerson.status = 'TERMINATED';
      } else {
        inputPerson.status = 'LEAVER';
      }
    }
    const personData = this.processPerson(inputPerson);
    const { contracts: mappedContracts, ...person } = calculateManningContracts(personData); //check active contract is active emailCtrl
    const upsertPerson = person.id ? this.updatePerson(person) : this.createPerson(hotelId, { ...person, contracts: mappedContracts });
    return upsertPerson.pipe(
      mergeMap(({ id: personId, personnelNo, hotel }) => {
        person.id = personId;
        person.personnelNo = personnelNo;
        person.hotelId = hotel.id;
        person.holidexCode = hotel.holidexCode;
        if (account) {
          const obs: Observable<string> = skipAccountChanges
            ? of(account.id).pipe(first())
            : removeAccount
            ? this.removeAccount({ accountId: account.id, personId }).pipe(
                first(),
                map(() => undefined)
              )
            : this.saveAccount(account, { personId, hotelId });
          return obs.pipe(
            mergeMap((accountId) =>
              this.savePersonContracts(person.id, mappedContracts).pipe(
                first(),
                map((contracts) => ({
                  accountId,
                  hasAccount: !!accountId,
                  accountStatus: accountId ? 'Yes' : ('No' as 'Yes' | 'No'),
                  contracts,
                }))
              )
            ),
            map((data) => calculateManningContracts({ ...person, ...data })),
            mergeMap((p) => {
              if (p.contractsEndDate && !dayjs().isBefore(p.contractsEndDate)) {
                return this.refreshPersonStatus(p);
              } else {
                return of(p);
              }
            })
          );
        } else {
          return this.savePersonContracts(person.id, mappedContracts).pipe(
            map((contracts) => calculateManningContracts({ ...person, contracts })),
            mergeMap((p) => {
              if (p.contractsEndDate && !dayjs().isBefore(p.contractsEndDate)) {
                return this.refreshPersonStatus(p);
              } else {
                return of(p);
              }
            })
          );
        }
      })
    );
  }

  private refreshPersonStatus(person: ManningPerson) {
    return this.getPersonStatus(person.id).pipe(
      first(),
      map((status) => ({
        ...person,
        ...status,
        statusText: this.getPersonStatusText(status.status, person.contracts, status.leavingDate, status.contractsEndDate),
      }))
    );
  }

  private upsertAccount(
    { roles: { actual, captureShift, forecast, manning, shift, user }, departments, email, id, policyName }: IAccount,
    { personId, hotelId, regionId }: { personId?: string; hotelId; regionId }
  ): Observable<{ id }> {
    const mapRights = (role: IRights) => [
      ...(role.read ? ['READ'] : []),
      ...(role.write ? ['WRITE'] : []),
      ...(role.approve ? ['APPROVE'] : []),
    ];
    const data = {
      personId,
      regionId,
      hotelId,
      actual: mapRights(actual),
      captureShift: mapRights(captureShift),
      forecast: mapRights(forecast),
      manning: mapRights(manning),
      shift: mapRights(shift),
      user: mapRights(user),
      departments: departments.map(({ name: departmentName, outletIndex, outletType, hotelId, subDeptName }) => ({
        hotelId,
        departmentName,
        outletIndex,
        outletType,
        subDeptName,
      })),
      policyName: policyName || 'SCHEDULER',
    };
    if (!id) {
      return this.linkService
        .GQLCentral(false)
        .mutate({
          mutation: gql`
            mutation createSchedulingAccount($data: AccountSchedulerCreateInput!) {
              createSchedulingAccount(data: $data) {
                ...${accountfragment.name}
              }
            }
            ${accountfragment.definition}
          `,
          variables: {
            data: {
              ...data,
              email,
            },
          },
        })
        .pipe(map(({ data }: any) => data.createSchedulingAccount));
    } else {
      return this.linkService
        .GQLCentral(false)
        .mutate({
          mutation: gql`
            mutation updateSchedulingAccount($data: AccountSchedulerUpdateInput!, $id: ID!) {
              updateSchedulingAccount(data: $data, id: $id) {
                ...${accountfragment.name}
              }
            }
            ${accountfragment.definition}
          `,
          variables: {
            id,
            data,
          },
        })
        .pipe(
          map(({ data }: any) => {
            return data.updateSchedulingAccount;
          })
        );
    }
  }

  private removeAccount({ accountId, personId }: { accountId; personId: string }) {
    const regionId = this.contextService.getCurrentRegionId();
    return this.linkService
      .GQLCentral(false)
      .mutate({
        mutation: gql`
          mutation removeSchedulingAccount($accountId: ID!, $personId: ID!, $regionId: ID!) {
            removePersonFromSchedulingAccount(id: $accountId, personId: $personId, regionId: $regionId) {
              id
            }
          }
        `,
        variables: { accountId, personId, regionId },
      })
      .pipe(map(({ data }: any) => data.removePersonFromSchedulingAccount));
  }

  private createPerson(hotelId: string, pin: ManningPerson) {
    const lastContract = pin.contracts?.length && pin.contracts[pin.contracts.length - 1];
    if (!pin.activeContract && !lastContract) {
      this.log.error('Person has no active or last contract', {
        activeContract: pin.activeContract,
        contracts: pin.contracts,
      });
      throw new Error('Unable to determine new person contract data');
    }
    const per: Record<string, any> = {
      ...(pin.personnelNo ? { personnelNo: pin.personnelNo } : {}),
      checkInId: pin.checkInId || null,
      firstName: pin.firstName,
      lastName: pin.lastName,
      status: !pin.leaving ? 'ACTIVE' : !pin.leavingDate || dayjs().isAfter(pin.leavingDate) ? 'TERMINATED' : 'LEAVER',
      publicHolidayDays: pin.publicHolidayDays || 0,
      publicHolidayHours: pin.publicHolidayHours || 0,
      holidayDays: pin.holidayDays || 0,
      holidayHours: pin.holidayHours || 0,
      sickDays: pin.sickDays || 0,
      active: true,
      agency: pin.agency,
      leaveStartDate: pin.leaveStartDate,
      leaveEndDate: pin.leaveEndDate,
      leavingDate: pin.leavingDate,
      contractedHours: +(pin.activeContract ? pin.activeContract.contractedHours : lastContract.contractedHours),
      workDays: +(pin.activeContract ? pin.activeContract.workDays : lastContract.workDays),
      startDate: pin.activeContract?.start || lastContract.start,
      jobBand: +(pin.activeContract ? pin.activeContract.jobBand : lastContract.jobBand),
    };

    if (pin.activeContract?.role && pin.activeContract?.department) {
      per.departmentName = pin.activeContract.department.name;
      if (pin.activeContract.department.outletType) {
        per.outlet = {
          type: pin.activeContract.department.outletType,
          index: pin.activeContract.department.outletIndex,
        };
      }
      if (pin.activeContract.department.subDeptName) {
        per.subDeptName = pin.activeContract.department.subDeptName;
      }
      per.roleName = pin.activeContract.role.name;
    } else if (lastContract?.department && lastContract?.role) {
      per.departmentName = lastContract.department.name;
      if (lastContract.department.outletType) {
        per.outlet = {
          type: lastContract.department.outletType,
          index: lastContract.department.outletIndex,
        };
      }
      if (lastContract.department.subDeptName) {
        per.subDeptName = lastContract.department.subDeptName;
      }
      per.roleName = lastContract.role.name;
    } else {
      this.log.error('Unable to determine new person contract data', {
        activeDepartment: pin.activeContract?.department,
        lastDepartment: pin.contracts?.length && pin.contracts[pin.contracts.length - 1].department,
      });
      throw new Error('Unable to determine new person contract data');
    }

    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation createPerson($hotelId: ID!, $data: PersonInput!) {
                createPerson(hotelId: $hotelId, data: $data) {
                  ...${basicpersonfragment.name}
                }
              }
              ${basicpersonfragment.definition}
            `,
            variables: {
              hotelId,
              data: per,
            },
          })
          .pipe(map((e: any) => e.data.createPerson))
      )
    );
  }

  private updatePerson(pin: Omit<ManningPerson, 'contracts'>) {
    const per: Record<string, any> = {
      ...(pin.personnelNo ? { personnelNo: pin.personnelNo } : {}),
      checkInId: pin.checkInId || null,
      firstName: pin.firstName,
      lastName: pin.lastName,
      status: !pin.leaving ? 'ACTIVE' : !pin.leavingDate || dayjs().isAfter(pin.leavingDate) ? 'TERMINATED' : 'LEAVER',
      active: true,
      leaveStartDate: pin.leaveStartDate,
      leaveEndDate: pin.leaveEndDate,
      leavingDate: pin.leavingDate,
      publicHolidayDays: pin.publicHolidayDays || 0,
      publicHolidayHours: pin.publicHolidayHours || 0,
      holidayDays: pin.holidayDays || 0,
      holidayHours: pin.holidayHours || 0,
      sickDays: pin.sickDays || 0,
      hotelId: pin.hotelId,
      agency: pin.agency,
    };
    if (pin.activeContract?.role) {
      per.roleName = pin.activeContract.role.name;
    }
    if (pin.activeContract?.jobBand) {
      per.jobBand = +pin.activeContract.jobBand;
    }
    if (pin.activeContract?.department) {
      per.departmentName = pin.activeContract.department.name;
      if (pin.activeContract.department.outletType) {
        per.outlet = {
          type: pin.activeContract.department.outletType,
          index: pin.activeContract.department.outletIndex,
        };
      }
      if (pin.activeContract.department.subDeptName) {
        per.subDeptName = pin.activeContract.department.subDeptName;
      }
    }
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation updatePerson($personId: ID!, $data: PersonUpdateInput!) {
                updatePerson(personId: $personId, data: $data) {
                  ...${basicpersonfragment.name}
                }
              }
              ${basicpersonfragment.definition}
            `,
            variables: {
              personId: pin.id,
              data: per,
            },
          })
          .pipe(map((e: any) => e.data.updatePerson))
      )
    );
  }

  private updatePersonAccount(personId: string, accountId: string): Observable<{ accountId; id }> {
    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate({
            mutation: gql`
              mutation updatePersonAccount($personId: ID!, $accountId: ID!) {
                updatePersonAccount(personId: $personId, accountId: $accountId) {
                  ...${basicpersonfragment.name}
                }
              }
              ${basicpersonfragment.definition}
            `,
            variables: {
              personId,
              accountId,
            },
          })
          .pipe(map(({ data }: any) => data.updatePersonAccount))
      )
    );
  }

  private savePersonContracts(personId, contracts: ManningContract[]): Observable<ManningContract[]> {
    const homeHotelId = this.linkService.getCurrentHotelId();

    const makeDepartment = ({ hotelId, ...dep }: FullDepartment) => ({
      //local transform
      hotelId: hotelId || homeHotelId,
      departmentName: dep.name,
      subDeptName: dep.subDeptName,
      ...(dep.outletType && {
        outlet: {
          type: dep.outletType,
          index: dep.outletIndex,
        },
      }),
    });

    const newContracts = contracts.map((contract) => ({
      id: contract.id,
      hotelId: contract.hotelId || homeHotelId,
      department: makeDepartment(contract.department),
      otherDepartments: contract.otherDepartments?.map((d) => makeDepartment(d)) ?? [],
      start: contract.start,
      end: contract.end,
      roleName: contract.role.name,
      jobBand: contract.jobBand,
      payRate: contract.payRate,
      contractedHours: contract.contractedHours,
      title: contract.title || null,
      workDays: contract.workDays,
      casual: contract.casual,
    }));

    return this.linkService.GQLRegion().pipe(
      first(),
      mergeMap((link) =>
        link
          .mutate<{ updatePersonContracts?: any[] }>({
            mutation: gql`
              mutation updatePersonContracts($personId: ID!, $newContracts: [PersonContractInput!]!) {
                updatePersonContracts(personId: $personId, data: $newContracts) {
                  ...${personcontractfragment.name}
                }
              }
              ${personcontractfragment.definition}
            `,
            variables: {
              personId,
              newContracts,
            },
          })
          .pipe(
            map((e) => {
              if (e.data?.updatePersonContracts.length > 0) {
                return e.data.updatePersonContracts.map(({ roleName, start, end, department, otherDepartments, ...contract }) => ({
                  ...contract,
                  roleName,
                  department: this.matchFullDepartment(department),
                  otherDepartments: otherDepartments?.map((dept) => this.matchFullDepartment(dept)) ?? [],
                  role: this.roles.find((r) => r.name === roleName),
                  start: new Date(start),
                  end: end ? new Date(end) : null,
                }));
              }
              return [];
            })
          )
      )
    );
  }
}

const personcontractfragment = {
  name: 'ManningPersonContractFragment',
  definition: gql`
    fragment ManningPersonContractFragment on PersonContract {
      id
      department {
        holidexCode
        hotelId
        departmentName
        subDeptName
        outletType
        outletIndex
      }
      otherDepartments {
        holidexCode
        hotelId
        departmentName
        subDeptName
        outletType
        outletIndex
      }
      start
      end
      roleName
      jobBand
      payRate
      contractedHours
      workDays
      title
      casual
    }
  `,
};

const basicpersonfragment = {
  name: 'ManningPersonFragment',
  definition: gql`
    fragment ManningPersonFragment on Person {
      accountId
      account {
        email,
        enabled
      }
      id
      agency
      firstName
      lastName
      departmentName
      subDeptName
      departmentLabel
      outletType
      outletIndex
      personnelNo
      roleName
      jobBand
      holidayDays
      holidayHours
      publicHolidayDays
      sickDays
      status
      leaveStartDate
      leaveEndDate
      leavingDate
      contractsEndDate
      checkInId
      hotel { id, holidexCode }
      contracts {
        ...${personcontractfragment.name}
      }
      currentContract {
        ...${personcontractfragment.name}
      }
    }
    ${personcontractfragment.definition}
  `,
};

const personholidayfragment = {
  name: 'PersonHolidayFragment',
  definition: gql`
    fragment PersonHolidayFragment on Person {
      annualHolidaysTaken: actualHoliday(holidayType: ANNUAL_LEAVE) {
        days
        hours
        startDate
        holidayType
        statsType
        updatedAt
        lastDay
      }
      publicHolidaysTaken: actualHoliday(holidayType: PUBLIC_HOLIDAY) {
        days
        hours
        startDate
        holidayType
        statsType
        updatedAt
        lastDay
      }
    }
  `,
};

const accountfragment = {
  name: 'ManningAccountFragment',
  definition: gql`
    fragment ManningAccountFragment on Account {
      id
      email
      roles
      enabled
      schedulerDepartments {
        holidexCode
        hotelId
        departmentName
        subDeptName
        outletIndex
        outletType
      }
      schedulerHotelAccess {
        allDepartments
        departments {
          departmentName
          outletIndex
          outletType
          subDeptName
        }
        holidexCode
        hotelId
        regionAbbreviation
      }
      policyName
    }
  `,
};
