<p-panel toggleable="true" toggler="header" [collapsed]="!showOpen" (onAfterToggle)="toggle($event)" *ngIf="_contract">
  <ng-template pTemplate="header">
    <label
      >{{
        (_contract.role ? _contract.role.label : 'New position') +
          ' : ' +
          (_contract.start
            ? (_contract.start | date: 'dd MMM yyyy') + (_contract.end ? ' - ' + (_contract.end | date: 'dd MMM yyyy') : ' - ')
            : '')
      }}
      <b *ngIf="_contract.isActive">ACTIVE CONTRACT</b></label
    >
    <p-message *ngIf="!_contract.isValid" severity="error" text="Contract Incomplete"></p-message>
  </ng-template>
  <ng-template pTemplate="footer">
    <div style="text-align: right">
      <button
        type="button"
        pButton
        icon="pi pi-minus"
        (click)="removeContract()"
        label="Remove"
        class="p-ml-auto p-button-sm p-button-danger"
        *ngIf="!disabled"
      ></button>
    </div>
  </ng-template>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col-12" *ngIf="otherClusterHotels?.length > 0">
      <div class="contract-heading">Home hotel departments</div>
    </div>
    <div class="p-field p-col-6 contract-details">
      <label for="contract-dep"
        >Home Department
        <app-full-department-drop-down
          id="contract-dep"
          [(ngModel)]="department"
          [hotelId]="hotelId"
          required
          #departmentModel="ngModel"
          (onChange)="contractChange()"
          [disabled]="disabled"
          (onChange)="depChange()"
        >
        </app-full-department-drop-down>
      </label>
      <p-message *ngIf="departmentModel.invalid" severity="error" text="Required"></p-message>
    </div>
    <div class="p-field p-col-6 contract-details">
      <label
        >Other {{ holidexCode }} Departments
        <app-full-department-drop-down
          multi="true"
          [(ngModel)]="otherDepartments"
          [hotelId]="hotelId"
          [excludeDepts]="[department]"
          [disabled]="disabled"
          (onChange)="otherChange()"
        >
        </app-full-department-drop-down>
      </label>
    </div>
    <div class="p-field p-col-12" *ngIf="otherClusterHotels?.length > 0">
      <div class="contract-heading">Shared {{ cluster?.name }} cluster departments</div>
    </div>
    <div class="p-field p-col-6 contract-details" *ngFor="let hotel of otherClusterHotels">
      <label
        >Other {{ hotel.holidexCode }} Departments
        <app-full-department-drop-down
          multi="true"
          [(ngModel)]="clusterDepartments[hotel.holidexCode]"
          [hotelId]="hotel.id"
          [excludeDepts]="hotel.id === hotelId ? [department] : []"
          [disabled]="disabled"
          (onChange)="clusterDeptChange(hotel.holidexCode)"
          [useCluster]="true"
        >
        </app-full-department-drop-down>
      </label>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col-12">
      <div class="contract-heading">Contract details</div>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col contract-details">
      <label
        >Job Role
        <app-role-drop-down [(ngModel)]="_contract.role" #role="ngModel" required (onChange)="contractChange()" [disabled]="disabled">
        </app-role-drop-down>
      </label>
      <p-message *ngIf="role.invalid" severity="error" text="Required"></p-message>
    </div>
    <div class="p-field p-col contract-details">
      <label
        >Business Title
        <input
          pInputText
          size="30"
          [(ngModel)]="_contract.title"
          #title="ngModel"
          (change)="contractChange()"
          [disabled]="disabled"
          required
        />
      </label>
      <p-message *ngIf="title.invalid" severity="error" text="Required"></p-message>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col contract-details" *ngIf="!agency">
      <label
        >Job Band
        <p-inputNumber
          size="2"
          min="1"
          max="10"
          showButtons="true"
          #jobBand="ngModel"
          [(ngModel)]="_contract.jobBand"
          [disabled]="disabled"
        ></p-inputNumber>
      </label>
      <p-message *ngIf="jobBand.invalid" severity="error" text="Required"></p-message>
      <p-message *ngIf="!jobBand.invalid && !isJobBandValid()" severity="error" text="Invalid job band"></p-message>
    </div>
    <div class="p-field p-col contract-details" *ngIf="!agency && usePayRate">
      <label
        >Pay Rate <ng-container *ngIf="currency">(per hour) </ng-container>
        <p-inputNumber
          size="24"
          mode="currency"
          [currency]="currency"
          currencyDisplay="code"
          #payRate="ngModel"
          [(ngModel)]="_contract.payRate"
          [disabled]="disabled"
        ></p-inputNumber>
      </label>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col contract-details">
      <label
        >0-hour hotel employee (e.g. daily worker or hotel direct casual worker)
        <p-checkbox [disabled]="disabled" binary="true" [(ngModel)]="_contract.casual"></p-checkbox>
      </label>
    </div>
  </div>
  <div class="p-formgrid p-grid" *ngIf="!_contract.casual && !agency">
    <div class="p-field p-col contract-details">
      <label
        >Contracted Hours
        <p-inputNumber
          size="4"
          min="0"
          max="99"
          mode="decimal"
          [maxFractionDigits]="1"
          [(ngModel)]="_contract.contractedHours"
          [disabled]="disabled"
        ></p-inputNumber>
      </label>
      <label style="margin-left: 0.5em"> working hours per week</label>
    </div>
    <div class="p-field p-col contract-details">
      <label
        >Work Days
        <p-inputNumber
          size="3"
          mode="decimal"
          min="0.5"
          max="7"
          [maxFractionDigits]="1"
          [(ngModel)]="_contract.workDays"
          [disabled]="disabled"
        ></p-inputNumber>
      </label>
      <label style="margin-left: 0.5em"> days per week</label>
    </div>
  </div>
  <div class="p-formgrid p-grid">
    <div class="p-field p-col contract-details">
      <label
        >Start Date
        <p-calendar
          [(ngModel)]="_contract.start"
          dateFormat="d/m/yy"
          [showIcon]="true"
          appendTo="body"
          required
          #start="ngModel"
          (onSelect)="contractChange()"
          [disabled]="disabled"
          [showButtonBar]="true"
        ></p-calendar>
        <p-message *ngIf="start.invalid" severity="error" text="Required"></p-message>
        <p-message *ngIf="_contract.overlap" severity="error" text="Overlapping contract"></p-message>
      </label>
    </div>
    <div class="p-field p-col contract-details">
      <label
        >End Date
        <p-calendar
          [(ngModel)]="_contract.end"
          dateFormat="d/m/yy"
          [showIcon]="true"
          appendTo="body"
          (onSelect)="contractChange()"
          [disabled]="disabled"
          [showButtonBar]="true"
        ></p-calendar> </label
      ><label style="margin-left: 0.5em">(optional)</label>
    </div>
  </div>
</p-panel>
