import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import { LocalService } from 'src/services/local.service';
import { ScheduleDataService } from 'src/services/schedule-data.service';

import { FullDepartment } from 'src/commontypes/manning';
import { dayLength, CalendarLength, cloneShifts, Scope, Right } from 'src/commontypes/util';
import { RegionDataService } from 'src/services/region-data.service';
import { Subject, Subscription, timer } from 'rxjs';
import { LoggingService } from 'src/services/logging.service';
import { distinctUntilKeyChanged, distinctUntilChanged, filter, first, tap, delay, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextService } from 'src/services/context.service';
import { SentryService } from 'src/services/sentry.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/services/auth.service';
import { environment } from 'src/environments/environment';

export interface IScheduleStatus {
  status: string;
  missedSubmit: boolean;
  missedApprove: boolean;
  scheduleId?: string;
  scheduleApproveDate?: Date;
  scheduleSubmitDate?: Date;
  scheduleAutoDate?: Date;
  captureApproveDate?: Date;
  captureSubmitDate?: Date;
  captureAutoDate?: Date;
  day?: string;
}

type CheckinMatchCode = 'NOMATCH' | 'FIXED' | 'END' | 'BAD' | 'MATCH';
export interface ICheckin {
  checkInType: 'IN' | 'OUT';
  time;
  length?;
  checkInDay;
  shiftMatch?: CheckinMatchCode;
}

const TOLERANCE_MINS = 15;

@Component({
  selector: 'app-shift-display',
  styleUrls: ['./shift-display.component.scss'],
  templateUrl: './shift-display.component.html',
})
export class ShiftDisplayComponent implements OnInit, OnDestroy {
  @Input() loadCapturedShift = false;
  @Input() showColAddOnSelection = true;
  @Input() showColSave = true;
  @Input() showColApprove = true;
  @Input() extraCommand: string = null;
  @Input() extraCommandIcon: string = 'pi-check';
  @Input() extraCommand2: string = null;
  @Input() extraCommandIcon2: string = 'pi-check';
  @Input() helpfiles = null;
  @Input() allowMultDepartments = false;

  @Output() shiftClicked = new EventEmitter<any>();
  @Output() cellAddClicked = new EventEmitter<any>();
  @Output() colAddClicked = new EventEmitter<any>();
  @Output() colSaveClicked = new EventEmitter<any>();
  @Output() colApproveClicked = new EventEmitter<any>();
  @Output() colUnapproveClicked = new EventEmitter<any>();
  @Output() exportClicked = new EventEmitter<any>();
  @Output() extraCommandClicked = new EventEmitter<any>();
  @Output() extraCommandClicked2 = new EventEmitter<any>();
  @Output() shiftMoved = new EventEmitter<any>();

  private loadSub$: Subscription;
  private department$: Subscription;
  private hotel$: Subscription;
  selectedTime = new Date();
  timeStart: dayjs.Dayjs;
  timeEnd: dayjs.Dayjs;
  viewHolidays = false;

  yearRange = `${dayjs().subtract(1, 'year').year()}:${dayjs().add(3, 'year').year()}`;

  useMultDeps = false;
  showDepartment?: FullDepartment = null;
  showDepartmentsList = [];
  showDepartments: { name; outletType?; outletIndex?; subDeptName? }[];
  employeeSort = 0;

  allowBlockChange = false;
  showBlocks = false;
  displayTimeOffset = 0;
  displayCells = 1;
  displayDateList: dayjs.Dayjs[] = [];
  dateTotals: number[];
  dateOptimals: number[];
  dateOptimalsByDep: number[][];
  dateShiftsConfirmed: number[];
  dateShiftsCount: number[];
  dateScheduleStatus: IScheduleStatus[];
  cellShiftsCount: number[];
  cellShiftsEmployees: Array<Map<string, number>>;
  dateShiftsCaptured: number[];
  showPersonChecks = false;
  approveIcon = 'pi-check-square';
  unapproveIcon = 'pi-check-square';
  missedUnapproveIcon = 'pi-desktop';
  saveIcon = 'pi-save';
  optimalSub$ = new Subscription();
  private setDepartment = false;
  public useLinkOverride = false;
  public dateToday = dayjs();

  displayCellLength = 1;
  daysShown = 1;
  dropRight = false;
  departmentList: FullDepartment[] = null;
  pTypes = [
    { label: 'Home Department', display: true, count: 0 },
    { label: 'Other Departments', display: true, count: 0 },
    { label: 'Other Hotels', display: true, count: 0 },
    { label: '0-hour Hotel Employees', display: true, count: 0 },
    { label: 'Named Agency Employees', display: true, count: 0 },
    { label: 'Agency', display: true, count: 1 },
  ];

  checkAll = false;
  checkedCount = 0;

  displayRows = [];
  allPersons = [];
  isLoading = false;
  isScheduleLoading = false;
  isDeptFiltering = false;

  allScheduleStatus = [];
  isPersonAdmin = false;
  canViewHolidays = false;
  holidayType: 'days' | 'hours' = 'days';
  holidayStart: dayjs.Dayjs;
  showTime: CalendarLength = null;
  showTimes: Array<{
    code: CalendarLength;
    label: string;
    interval: 'days' | 'months' | 'weeks';
    cols?: number;
    colLength: number;
    disabled?: boolean;
  }> = [
    { code: CalendarLength.DAY, label: 'Day', interval: 'days', cols: 24, colLength: dayLength / 24 },
    { code: CalendarLength.MON_WEEK, label: 'Week (Mon - Sun)', interval: 'weeks', cols: 7, colLength: dayLength },
    { code: CalendarLength.SUN_WEEK, label: 'Week (Sun - Sat)', interval: 'weeks', cols: 7, colLength: dayLength },
    { code: CalendarLength.MONTH, label: 'Month', interval: 'months', colLength: dayLength },
  ];

  sortMenu = [
    {
      label: 'Sort',
      items: [
        {
          label: 'by Job Band by Name',
          command: () => {
            this.setSort(0);
          },
        },
        {
          label: 'by Last Name',
          command: () => {
            this.setSort(1);
          },
        },
        {
          label: 'by Business Role',
          command: () => {
            this.setSort(2);
          },
        },
      ],
    },
  ];

  public addOptions = [];
  backRoute: string;
  private draggedShift: any;
  private draggedRow: any;

  /** used to track department changes */
  $depChange = new Subject<boolean>();
  dataKey$: Subscription;
  depChangeSub$: Subscription;
  timezone$: any;
  updated = undefined;
  checkInData: { checkIns: ICheckin[]; personnelId }[] = [];

  constructor(
    public scheduleData: ScheduleDataService,
    private regionData: RegionDataService,
    public context: ContextService,
    private local: LocalService,
    private log: LoggingService,
    private route: ActivatedRoute,
    private router: Router,
    private sentry: SentryService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.dateToday = this.context.jsDateToUTC(new Date()).startOf('day');
    this.showPersonChecks = !this.loadCapturedShift;
    this.authService
      .hasAnyRoles([
        [Scope.REGION_ADMIN, '*'],
        [Scope.SCHEDULE_ADMIN, '*'],
        [Scope.SCHEDULE_MANNING, '*'],
        [Scope.SCHEDULE_USER, '*'],
      ])
      .pipe(first())
      .subscribe((hasRoles) => {
        this.isPersonAdmin = hasRoles;
      });
    this.authService
      .hasAnyRoles([
        [Scope.REGION_ADMIN, Right.READ],
        [Scope.USER, Right.READ],
        [Scope.SCHEDULE_ADMIN, Right.READ],
        [Scope.SCHEDULE_USER, Right.READ],
      ])
      .subscribe((canViewHolidays) => {
        if (environment.showHolidaysTaken) {
          this.canViewHolidays = canViewHolidays;
        } else {
          this.canViewHolidays = false;
        }
      });

    if (this.loadCapturedShift) {
      this.approveIcon = 'pi-lock-open';
      this.unapproveIcon = 'pi-lock';
      this.missedUnapproveIcon = 'pi-lock';
      this.saveIcon = 'pi-pencil';
    }

    this.setupDepChangeSub();
    if (this.route.snapshot.paramMap.get('showTime')) {
      switch (+this.route.snapshot.paramMap.get('showTime')) {
        case 0:
          this.showTime = CalendarLength.MON_WEEK;
          break;
        case 1:
          this.showTime = CalendarLength.SUN_WEEK;
          break;
        case 2:
          this.showTime = CalendarLength.MONTH;
          break;
      }
      this.selectedTime = new Date(+this.route.snapshot.paramMap.get('startDate'));
      this.backRoute = this.route.snapshot.paramMap.get('backRoute');
      this.setDepartment = true;
      this.useLinkOverride = true;
      this.dateChange(null, true); // reload data
    } else {
      //okay show the default for the hotel
      switch (this.context.getCurrentBasicHotel().firstWeekday) {
        case 'MONDAY':
          this.showTimes[1].disabled = false;
          this.showTimes[2].disabled = true;
          this.showTime = CalendarLength.MON_WEEK;
          break;
        default:
          this.showTimes[1].disabled = true;
          this.showTimes[2].disabled = false;
          this.showTime = CalendarLength.SUN_WEEK;
          break;
      }
    }
    this.local
      .isReady()
      .pipe(
        filter((ready) => ready),
        first()
      )
      .subscribe(() => {
        const recentDay = this.local.getRecentlyUsedList('currentDay');
        const date = recentDay && recentDay.length > 0 ? new Date(recentDay[0]) : new Date();
        if (!recentDay || recentDay.length === 0) {
          this.local.addRecentlyUsedList('currentDay', date.toISOString(), (d) => dayjs(d).isSame(date, 'day'));
        }
        this.selectedTime = date;

        // check if we need to initialize the showTime
        if (this.showTime === null || this.showTime === undefined) {
          this.showTime = CalendarLength.MON_WEEK;
        }

        this.dateChange();
        //also set up to detect any hotel change
        this.hotel$ = this.context
          .getCurrentBasicHotel$()
          .pipe(
            filter((hotel) => !!hotel && hotel.id),
            distinctUntilKeyChanged('id')
          )
          .subscribe((hotel) => {
            if (hotel.holidayConfig) {
              this.holidayType = hotel.holidayConfig.type === 'HOURS' ? 'hours' : 'days';
              const d = dayjs(`${hotel.holidayConfig.startDay || 1} ${hotel.holidayConfig.startMonth || 'JAN'} ${dayjs().year()}`);
              if (d.isAfter(new Date(), 'day')) {
                this.holidayStart = d.subtract(1, 'year');
              } else {
                this.holidayStart = d;
              }
            } else {
              this.holidayType = 'days';
              this.holidayStart = dayjs(`1 JAN ${dayjs().year()}`);
            }
            switch (this.context.getCurrentBasicHotel().firstWeekday) {
              case 'MONDAY':
                this.showTimes[1].disabled = false;
                this.showTimes[2].disabled = true;
                this.showTime = CalendarLength.MON_WEEK;
                break;
              default:
                this.showTimes[1].disabled = true;
                this.showTimes[2].disabled = false;
                this.showTime = CalendarLength.SUN_WEEK;
                break;
            }
            this.dateChange(null, true); // reload data
          });
      });
  }

  ngOnDestroy(): void {
    if (this.depChangeSub$) {
      this.depChangeSub$.unsubscribe();
      this.depChangeSub$ = undefined;
    }
    if (this.dataKey$) {
      this.dataKey$.unsubscribe();
      this.dataKey$ = undefined;
    }
    if (this.loadSub$) {
      this.loadSub$.unsubscribe();
      this.loadSub$ = undefined;
    }
    if (this.department$) {
      this.department$.unsubscribe();
      this.department$ = undefined;
    }
    if (this.hotel$) {
      this.hotel$.unsubscribe();
      this.hotel$ = undefined;
    }
    if (this.timezone$) {
      this.timezone$.unsubscribe();
      this.timezone$ = undefined;
    }
    if (this.optimalSub$) {
      this.optimalSub$.unsubscribe();
      this.optimalSub$ = undefined;
    }
  }

  setToday() {
    this.selectedTime = new Date();
    this.dateChange();
  }

  setCal(diff: number) {
    if (!this.showTimes.find((t) => t.code === this.showTime)) {
      this.sentry.sendMessage('Unable to find show time in setCal', 'info', {
        showTimes: JSON.stringify(this.showTimes),
        showTime: this.showTime,
      });
      // default to the available week view
      this.showTime = this.showTimes.find((s) => s.interval === 'weeks')?.code || CalendarLength.DAY;
    }
    const showTime = this.showTimes.find((t) => t.code === this.showTime) || this.showTimes[1] || this.showTimes[0];
    this.selectedTime = dayjs(this.selectedTime).add(diff, showTime.interval).toDate();
    this.dateChange();
  }

  dateChange(showTimeEvent?: CalendarLength, forceLoad?: Boolean) {
    const oldTime = this.timeStart;
    const showTimeChanged = showTimeEvent !== null && showTimeEvent !== undefined;
    if (!showTimeChanged && this.selectedTime) {
      this.local.addRecentlyUsedList('currentDay', this.selectedTime.toISOString(), (d) => dayjs(d).isSame(this.selectedTime, 'day'));
    }
    switch (this.showTime) {
      case CalendarLength.DAY:
        this.allowBlockChange = false;
        this.showBlocks = false;
        this.timeStart = this.context.jsDateToUTC(this.selectedTime);
        break;
      case CalendarLength.MON_WEEK:
        this.allowBlockChange = true;
        //set start to a monday
        this.timeStart = this.context.jsDateToUTC(this.selectedTime).subtract(1, 'day').startOf('week').add(1, 'day').startOf('day');
        break;
      case CalendarLength.SUN_WEEK:
        this.allowBlockChange = true;
        //set start to a sunday
        this.timeStart = this.context.jsDateToUTC(this.selectedTime).startOf('week').startOf('day');
        break;
      case CalendarLength.MONTH:
        this.allowBlockChange = false;
        this.showBlocks = true;
        this.timeStart = this.context.jsDateToUTC(this.selectedTime).startOf('month').startOf('day');
        break;
    }
    if (!dayjs(oldTime).isSame(this.timeStart, 'day') || showTimeChanged || forceLoad) {
      if (this.context.getCurrentBasicHotel()?.id && +this.timeStart > 0 && typeof this.showTime === 'number') {
        timer(20)
          .pipe(first())
          .subscribe(() => {
            this.loadData();
          });
      }
    } else {
      this.depChange();
    }
  }

  setupDepChangeSub() {
    if (this.depChangeSub$) {
      this.depChangeSub$.unsubscribe();
    }
    this.depChangeSub$ = this.$depChange
      .pipe(
        debounceTime(this.useMultDeps ? 2000 : 0),
        tap(() => (this.isDeptFiltering = true)),
        delay(10) // just need time to set up the controls
      )
      .subscribe(() => {
        if (this.isDeptSelected()) {
          // for some reason the start time and end time are not always set so this is a workaround for the code above
          if (!this.timeStart || !this.timeEnd) {
            this.setupTimes();
          }
          this.loadShifts();
        } else {
          this.clearData();
        }
      });
  }

  clearData() {
    this.displayRows = [];
    this.dateOptimals = new Array(this.daysShown).fill(0);
    this.dateTotals = new Array(this.daysShown).fill(0);
  }

  isDeptSelected() {
    return this.useMultDeps ? this.showDepartmentsList?.length > 0 : this.showDepartment;
  }

  multiDepChange() {
    this.setupDepChangeSub();
    this.depChange();
  }

  depChange() {
    //user changed the department
    timer(20)
      .pipe(first())
      .subscribe(() => {
        this.$depChange.next(true);
      });
  }

  loadShifts() {
    if (this.useMultDeps && this.showDepartmentsList?.length) this.showDepartments = [...this.showDepartmentsList];
    else this.showDepartments = this.showDepartment ? [this.showDepartment] : [];
    const departments = this.showDepartments;
    if (!departments?.length) {
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    if (this.loadSub$) {
      this.loadSub$.unsubscribe();
      this.loadSub$ = undefined;
    }
    const loadStart = this.timeStart.subtract(1, 'day').toDate();
    const loadEnd = this.timeEnd.toDate();
    const obs = this.loadCapturedShift
      ? this.scheduleData.getScheduledAndCapturedShifts(loadStart, loadEnd, null, departments[0], true, this.canViewHolidays)
      : this.scheduleData.getScheduledShifts(loadStart, loadEnd, null, departments, true, this.canViewHolidays);
    this.loadOptimalHours();
    this.loadSub$ = obs.subscribe(
      ({ data, personnelRefreshed }) => {
        this.updated = new Date();
        if (!personnelRefreshed) {
          this.messageService.clear('bottom-right');
          this.messageService.add({
            id: 'update_personnel',
            key: 'bottom-right',
            severity: 'info',
            summary: 'Checking for new personnel...',
            icon: 'pi-spin pi-spinner',
            sticky: true,
          });
        } else {
          this.messageService.clear('bottom-right');
        }
        const startTime = dayjs(this.timeStart);
        const endTime = startTime.add(1, 'month');
        const getCurrentContractIndex = (contracts?: Array<{ start: Date; end?: Date }>) =>
          contracts?.findIndex(({ start, end }) => endTime.isAfter(start) && (!end || startTime.isBefore(end)));

        this.allPersons = data
          .reduce((prev, { contracts, shifts, ...p }) => {
            if (!contracts || contracts.length === 0) {
              return prev;
            }

            const index = p.isAgency ? 0 : getCurrentContractIndex(contracts);
            if (index < 0 || ((p.status == 'LEAVER' || p.status == 'TERMINATED') && dayjs(this.timeStart).isAfter(dayjs(p.leavingDate)))) {
              return prev;
            }
            const currentContract = contracts[index];
            const nextContract = contracts[index + 1];
            let openEnded = !currentContract.end || nextContract ? !nextContract?.end : false;
            let end = openEnded ? undefined : nextContract?.end || currentContract.end;
            if (openEnded && p.leavingDate) {
              //actual staff termination force end on contract
              end = p.leavingDate;
              openEnded = false;
            }
            if (shifts?.some((shift) => !shift)) {
              this.sentry.sendMessage(
                'Empty shifts loaded',
                'warning',
                {
                  loadCapturedShift: this.loadCapturedShift,
                  isAgency: p.isAgency,
                  id: p.id,
                  loadStart,
                  loadEnd,
                  departments,
                },
                'shift-display-empty-shifts'
              );
            }
            const startedOn = contracts[0].start && dayjs(contracts[0].start);
            const holidayProRate = startedOn && startedOn.isAfter(this.holidayStart) ? startedOn.diff(this.holidayStart, 'days') / 365 : 1;
            const annualH = p.annualHolidaysTaken?.[0];
            const publicH = p.publicHolidaysTaken?.[0];

            const holidayTaken =
              this.holidayType === 'hours' ? (annualH?.hours || 0) + (publicH?.hours || 0) : (annualH?.days || 0) + (publicH?.days || 0);
            const holidayEntitlement =
              ((this.holidayType === 'hours' ? p.holidayHours : p.holidayDays + p.publicHolidayDays) || 0) * holidayProRate;
            const holidayOver = holidayTaken > holidayEntitlement;

            let updatedAt;
            if (publicH?.updatedAt && annualH?.updatedAt) {
              updatedAt = dayjs(publicH.updatedAt).isAfter(annualH.updatedAt) ? dayjs(publicH.updatedAt) : dayjs(annualH.updatedAt);
            } else if (publicH?.updatedAt) {
              updatedAt = dayjs(publicH?.updatedAt);
            } else if (annualH?.updatedAt) {
              updatedAt = dayjs(annualH?.updatedAt);
            }

            let lastDay = annualH?.lastDay;
            if (annualH?.lastDay && publicH?.lastDay) {
              lastDay = dayjs(publicH.lastDay).isAfter(annualH.lastDay) ? publicH.lastDay : annualH.lastDay;
            } else if (publicH?.lastDay) {
              lastDay = publicH.lastDay;
            }

            let holidayTip = '';
            if (updatedAt) {
              holidayTip += `Updated on ${updatedAt.format('YYYY-MM-DD')}`;
            }
            if (lastDay?.length) {
              if (holidayTip.length > 0) {
                holidayTip += '\n';
              }
              holidayTip += `Last recorded leave ${lastDay}`;
            }

            prev.push({
              ...p,
              holidayTaken,
              holidayEntitlement,
              holidayOver,
              holidayTip: holidayTip.length ? holidayTip : undefined,
              holidayProRate: holidayProRate < 1,
              shifts: shifts?.filter((shift) => !!shift) || [], // fix for https://sudoorgza.sentry.io/issues/5034560541/?project=5764862
              currentContract: contracts[index],
              onlyStartsAt: p.isAgency
                ? undefined
                : startTime.isBefore(currentContract.start)
                ? dayjs(currentContract.start).toDate()
                : undefined,
              endsAt: p.isAgency ? undefined : end && endTime.isAfter(end) ? dayjs(end).toDate() : undefined,
            });
            return prev;
          }, [])
          .sort((a, b) => (a.lastName > b.lastName ? 1 : -1));

        if (environment.useDeviceCheckIn) {
          this.integrateCheckInData();
        }
        this.filterByDepartment();
        if (this.displayRows?.length > 0) {
          this.isLoading = false;
        }
      },
      () => {
        this.messageService.clear('bottom-right');
        this.isLoading = false;
      },
      () => {
        this.messageService.clear('bottom-right');
        this.isLoading = false;
      }
    );
  }

  loadData() {
    this.isLoading = false;
    this.isScheduleLoading = false;
    this.setupTimes();
    this.isLoading = true;
    this.isScheduleLoading = true;
    this.LoadCheckInData();
    this.department$ = this.regionData
      .getFullDepartmentsForHotel()
      .pipe(
        distinctUntilChanged((a: FullDepartment[], b: FullDepartment[]) => {
          if (a.length !== b.length) {
            return true;
          }
          const set = new Set(a.map(({ label, hotelId }) => `${label}-${hotelId}`));
          if (!b.every(({ label, hotelId }) => set.delete(`${label}-${hotelId}`))) {
            return true;
          }
          return set.size !== 0;
        })
      )
      .subscribe(
        (result: FullDepartment[]) => {
          this.departmentList = result;
          const currentHotel = this.context.getCurrentBasicHotel();
          if (this.showDepartment && currentHotel) {
            if (this.showDepartment.hotelId !== currentHotel.id) {
              // this is different hotel so see if we can match the department
              const currentDept = this.showDepartment;
              this.showDepartment = this.departmentList.find(
                (d) =>
                  d.name === currentDept.name &&
                  (d.outletType
                    ? d.outletType === currentDept.outletType && d.outletIndex == currentDept.outletIndex
                    : !currentDept.outletType) &&
                  (d.subDeptName ? d.subDeptName === currentDept.subDeptName : !currentDept.subDeptName)
              );
            }
          }
          if (!this.showDepartment) {
            //figure out a department
            this.showDepartment = result && result[0];
          }
          if (this.setDepartment) {
            this.log.info('forcing department from outside');
            const dep = this.route.snapshot.paramMap.get('department');
            const ot =
              this.route.snapshot.paramMap.get('outletType') == 'undefined' || this.route.snapshot.paramMap.get('outletType') == 'null'
                ? null
                : this.route.snapshot.paramMap.get('outletType');
            const oi =
              this.route.snapshot.paramMap.get('outletType') == 'undefined' || this.route.snapshot.paramMap.get('outletType') == 'null'
                ? null
                : +this.route.snapshot.paramMap.get('outletIndex');
            const sd =
              this.route.snapshot.paramMap.get('subDeptName') == 'undefined' || this.route.snapshot.paramMap.get('subDeptName') == 'null'
                ? null
                : this.route.snapshot.paramMap.get('subDeptName');

            this.showDepartment = this.departmentList.find(
              (d) =>
                d.name == dep &&
                (d.outletType ? d.outletType == ot && d.outletIndex == oi : !ot) &&
                (d.subDeptName ? d.subDeptName == sd : !sd)
            );
            this.setDepartment = false;
          }
          if (this.showDepartment) {
            this.loadShifts();
          }

          this.scheduleData
            .getScheduleStatus(this.timeStart.toDate(), this.timeEnd.toDate())
            .pipe(first())
            .subscribe(
              (data) => {
                this.allScheduleStatus = data;
                this.filterScheduleStatus();
                this.isScheduleLoading = false;
              },
              () => {
                this.isScheduleLoading = false;
              }
            );
        },
        () => {
          this.isLoading = false;
          this.isScheduleLoading = false;
        }
      );
  }

  private LoadCheckInData() {
    if (environment.useDeviceCheckIn) {
      if (!this.loadCapturedShift) return; //not required for scheduling
      this.scheduleData.getCheckIns(this.timeStart.toDate(), this.timeEnd.toDate()).subscribe((data) => {
        //structure per user
        const newCheckinData = data.reduce((acc, entry) => {
          let p = acc.find((l) => l.personnelId == entry.personnelId);
          if (!p) {
            //not already here - lets add it
            p = {
              personnelId: entry.personnelId,
              checkIns: [] as ICheckin[],
            };
            acc.push(p);
          }
          p.checkIns.push({
            checkInType: entry.checkInType,
            checkInDay: this.context.isoToContextDate(entry.time).startOf('day'),
            time: dayjs(entry.time),
            length: 0,
          });
          return acc;
        }, [] as { checkIns: ICheckin[]; personnelId }[]);

        newCheckinData.forEach((entry) => {
          //now sort each user in the  list
          entry.checkIns.sort((a, b) => a.time - b.time);
          //run through the list and check for end dates
          entry.checkIns.forEach((checkIn, i) => {
            if (i + 1 >= entry.checkIns.length) return; //last one
            if (checkIn.checkInType != 'IN') return; //only on ins
            if (entry.checkIns[i + 1].checkInType != 'OUT') return; //only if followed by an out
            const len = entry.checkIns[i + 1].time.diff(checkIn.time, 'day', true);
            if (len > 0.6) return; //more than half a day isn't a shift
            checkIn.length = len;
          });
        });
        this.checkInData = newCheckinData;
        this.integrateCheckInData();
      });
    }
  }

  private integrateCheckInData() {
    //go through the people and add checkInData from the check in list if there is any
    this.allPersons.forEach((p) => {
      p.checkIns = this.checkInData.find((v) => v.personnelId == p.id)?.checkIns || [];
      this.matchPersonCheckins(p);
    });
  }

  private matchPersonCheckins(p: { checkIns: ICheckin[]; shifts? }) {
    const checkBad = (capturedShifts) => (capturedShifts?.[0] ? 'FIXED' : 'BAD');
    p.checkIns = p.checkIns.map((ci: ICheckin) => {
      //consider all the shifts and select a display status for shift match
      let code: CheckinMatchCode = 'NOMATCH'; //unmatched
      if (ci.checkInType == 'IN' && p.shifts) {
        for (const s of p.shifts) {
          const start = this.context.isoToContextDate(s.capturedShifts?.[0] ? s.capturedShifts[0].start : s.start);
          const end = this.context.isoToContextDate(s.capturedShifts?.[0] ? s.capturedShifts[0].end : s.end);
          const length = end.diff(start, 'days', true);
          const ciStart = this.context.isoToContextDate(ci.time);
          const ciEnd = this.context.isoToContextDate(ci.time).add(ci.length, 'days');
          const minutesToShiftStart = this.context.isoToContextDate(ci.time).diff(start, 'minutes');
          const minutesToShiftEnd = ciEnd.diff(end, 'minutes');
          const lengthDiffInMinutes = (ci.length - length) * (60 * 24);
          if (Math.abs(minutesToShiftStart) < TOLERANCE_MINS && Math.abs(minutesToShiftEnd) < TOLERANCE_MINS) {
            code = 'MATCH';
          } else if (+ciStart >= +start && +ciStart <= +end) {
            // check in starts between shift boundaries
            code = checkBad(s.capturedShifts);
          } else if (+ciEnd >= +start && +ciEnd <= +end) {
            // check in ends between shift boundaries
            code = checkBad(s.capturedShifts);
          } else if (Math.abs(minutesToShiftStart) < TOLERANCE_MINS * 4 && Math.abs(lengthDiffInMinutes) < TOLERANCE_MINS * 8) {
            // check in starts within broader tolerances
            code = checkBad(s.capturedShifts);
          }
        }
      } else if (ci.checkInType == 'OUT' && p.shifts) {
        for (const s of p.shifts) {
          const start = this.context.isoToContextDate(s.capturedShifts?.[0] ? s.capturedShifts[0].start : s.start);
          const end = this.context.isoToContextDate(s.capturedShifts?.[0] ? s.capturedShifts[0].end : s.end);
          const ciEnd = this.context.isoToContextDate(ci.time);
          const minutesToShiftEnd = this.context.isoToContextDate(ci.time).diff(end, 'minutes');
          if (Math.abs(minutesToShiftEnd) < TOLERANCE_MINS) {
            code = 'MATCH';
          } else if (+ciEnd >= +start && +ciEnd <= +end) {
            // check in ends between shift boundaries
            code = checkBad(s.capturedShifts);
          } else if (Math.abs(minutesToShiftEnd) < TOLERANCE_MINS * 4) {
            // check in starts within broader tolerances
            code = checkBad(s.capturedShifts);
          }
        }
      } else code = 'END';

      return {
        ...ci,
        shiftMatch: code,
      };
    });
  }

  private setupTimes() {
    if (!this.showTimes.find((t) => t.code === this.showTime)) {
      this.sentry.sendMessage('Unable to find show time in setupTimes', 'info', {
        showTimes: JSON.stringify(this.showTimes),
        showTime: this.showTime,
      });
      // default to the available week view
      this.showTime = this.showTimes.find((s) => s.interval === 'weeks')?.code || CalendarLength.DAY;
    }
    const showTime = this.showTimes.find((c) => c.code === this.showTime) || this.showTimes[1] || this.showTimes[0];
    this.displayCells = showTime.cols > 0 ? showTime.cols : dayjs(this.timeStart).daysInMonth();
    this.displayCellLength = showTime.colLength;
    this.daysShown = (this.displayCells * this.displayCellLength) / dayLength;
    this.timeEnd = this.timeStart.add(this.displayCells * this.displayCellLength, 'millisecond'); // ends at midnight of the last day of the interval
    this.displayDateList = new Array(this.displayCells)
      .fill(0)
      .map((v, i) => this.timeStart.add(this.displayCellLength * i, 'millisecond'));
    this.dateScheduleStatus = new Array(this.displayCells).fill({ status: 'XXX' });
  }

  private setSort(sort) {
    this.employeeSort = sort;
    this.filterByDepartment();
  }

  loadOptimalHours() {
    this.dateOptimals = new Array(this.daysShown).fill(0);

    if (!this.showDepartment) return; //no department selected
    if (this.optimalSub$) {
      this.optimalSub$.unsubscribe();
    }
    this.optimalSub$ = new Subscription();

    this.dateOptimalsByDep = this.showDepartments.map((d) => new Array(this.daysShown).fill(0));

    for (let di = 0; di < this.showDepartments.length; di += 1) {
      for (let i = 0; i < this.daysShown; i += 1) {
        let date = new Date(+this.timeStart + dayLength * i);
        if (this.loadCapturedShift) {
          const sub = this.scheduleData
            .getActualOptimalHours(
              date,
              this.showDepartments[di].name,
              this.showDepartments[di].subDeptName,
              this.showDepartments[di].outletType,
              this.showDepartments[di].outletIndex
            )
            .subscribe((hours) => {
              this.dateOptimalsByDep[di][i] = hours;
              this.dateOptimals[i] += hours;
            });
          this.optimalSub$.add(sub);
        } else {
          const sub = this.scheduleData
            .getOptimalHours(
              date,
              this.showDepartments[di].name,
              this.showDepartments[di].subDeptName,
              this.showDepartments[di].outletType,
              this.showDepartments[di].outletIndex
            )
            .subscribe((hours) => {
              this.dateOptimalsByDep[di][i] = hours;
              this.dateOptimals[i] += hours;
            });
          this.optimalSub$.add(sub);
        }
      }
    }
  }

  filterByDepartment() {
    this.isDeptFiltering = true;
    try {
      this.loadOptimalHours();
      this.filterScheduleStatus();
      this.pTypes.forEach((t) => (t.count = 0));
      const currentHotel = this.context.getCurrentBasicHotel();
      this.displayRows = this.allPersons.filter((p) => {
        if (!this.showDepartment) return false; //include no-one no department filter
        if (p.isAgency) {
          p.pType = 5;
          this.pTypes[5].count += 1;
          return true; //always include this line
        }
        p.isCasual = !p?.currentContract?.contractedHours;
        p.tooltip = `T&A ID ${p.personnelNo}${p.onlyStartsAt ? '\nContract starts on ' + dayjs(p.onlyStartsAt).format('D MMM YYYY') : ''}${
          p.endsAt ? '\nContract ends on ' + dayjs(p.endsAt).format('D MMM YYYY') : ''
        }`;

        //check if our main department or other departments
        const deps: Array<{ departmentName; subDeptName?; outletType?; outletIndex?; holidexCode }> = p.currentContract
          ? [p.currentContract.department, ...(p.currentContract.otherDepartments || [])]
          : [];
        return deps.some((a, index) => {
          // check for any department/hotel combo
          if (a.holidexCode !== currentHotel.holidexCode) return false;

          //look for a matching department in our display list
          let dmatch = this.showDepartments.find((sd) => {
            if (a.departmentName !== sd.name) return false;
            if (sd.subDeptName) {
              if (a.subDeptName !== sd.subDeptName) return false;
            } else if (a.subDeptName) return false;
            if (sd.outletType) {
              if (a.outletType !== sd.outletType) return false;
              if (a.outletIndex !== sd.outletIndex) return false;
            } //we don't have outlet info associated
            else if (a.outletType) return false;
            return true;
          });

          if (!dmatch) return false; //we don;t want this record

          p.isOtherHotel = p.currentContract.department.holidexCode !== currentHotel.holidexCode;
          p.holidexCode = p.currentContract.department.holidexCode;
          p.isHomeDepartment = p.isOtherHotel ? false : index == 0;
          p.pType = p.agency ? 4 : p.isCasual ? 3 : p.isOtherHotel ? 2 : p.isHomeDepartment ? 0 : 1;
          this.pTypes[p.pType].count += 1;
          return true;
        });
      });
      //now resort according to employeeSort
      this.displayRows = this.displayRows.sort((a, b) => {
        if (a.pType > b.pType) return 1;
        if (a.pType < b.pType) return -1;
        switch (this.employeeSort) {
          case 0:
            if (a.jobBand > b.jobBand) return 1;
            if (a.jobBand < b.jobBand) return -1;
          //otherwsie drop through
          case 1:
            return a.lastName > b.lastName ? 1 : -1;
          case 2:
            if (a.currentContract.title > b.currentContract.title) return 1;
            if (a.currentContract.title < b.currentContract.title) return -1;
            return a.lastName > b.lastName ? 1 : -1;
        }
      });

      //Now Set Row Headers
      let header = -1;
      this.displayRows.forEach((p) => {
        p.drawHeader = p.pType != header;
        header = p.pType;
      });
      this.calculateAll();
    } catch (err) {
      this.sentry.sendError(err, { message: 'error while trying to filter depts' });
    }
    this.isDeptFiltering = false;
  }

  filterScheduleStatus() {
    this.displayDateList.forEach((d, i) => {
      let entry = this.allScheduleStatus.find((s) => {
        // right day and department
        // schedules return a calendar date in string format and should be matched with the calendar date of the display list
        if (d.format('YYYY-MM-DD') !== s.day) return false;
        if (s.departmentName != this.showDepartment.name) return false;
        if (this.showDepartment.subDeptName) {
          if (this.showDepartment.subDeptName !== s.subDeptName) return false;
        } else if (s.subDeptName) {
          return false;
        }
        if (this.showDepartment.outletType) {
          if (s.outletType != this.showDepartment.outletType) return false;
          if (s.outletIndex != this.showDepartment.outletIndex) return false;
        } //we don't have outlet info associated
        else if (s.outletType) return false;
        return true;
      });
      if (entry) {
        this.dateScheduleStatus[i] = {
          status: this.loadCapturedShift ? entry.captureStatus : entry.scheduleStatus,
          missedSubmit: this.loadCapturedShift ? false : !entry.scheduleSubmit,
          missedApprove: this.loadCapturedShift ? false : !!entry.scheduleAutoApprove || !entry.scheduleApprove,
          scheduleId: entry.scheduleId,
          scheduleApproveDate: entry.scheduleApprove,
          scheduleSubmitDate: entry.scheduleSubmit,
          scheduleAutoDate: entry.scheduleAutoApprove,
          captureApproveDate: entry.captureApprove,
          captureSubmitDate: entry.captureSubmit,
          captureAutoDate: entry.captureAutoApprove,
          day: entry.day,
        };
      } else this.dateScheduleStatus[i] = { status: 'AWAITING', missedSubmit: false, missedApprove: false };
    });
    this.calculateAll();
  }

  get optimalTotal() {
    return Math.round((this.dateOptimals ? this.dateOptimals.reduce((sum, total) => sum + total, 0) : 0) * 10) / 10;
  }

  get dateTotal() {
    return Math.round((this.dateTotals ? this.dateTotals.reduce((sum, total) => sum + total, 0) : 0) * 10) / 10;
  }

  openPerson(personId) {
    this.router.navigate([
      `/main/manning/${personId}`,
      {
        showTime: this.showTime,
        useMultDeps: this.useMultDeps,
        showDepartmentsList: this.showDepartmentsList,
        showDepartment: this.showDepartment,
        showBlocks: this.showBlocks,
        selectedTime: this.selectedTime,
      },
    ]);
  }

  calculateAll() {
    //clear the column totals
    this.dateTotals = new Array(this.daysShown).fill(0);
    this.dateShiftsCount = new Array(this.daysShown).fill(0);
    this.cellShiftsCount = new Array(this.displayCells).fill(0);
    this.dateShiftsCaptured = new Array(this.daysShown).fill(0);
    this.cellShiftsEmployees = new Array(this.displayCells).fill(undefined).map(() => new Map<string, number>());
    this.dateShiftsConfirmed = new Array(this.daysShown).fill(0);

    const currentHotel = this.context.getCurrentBasicHotel();

    //walk the rows
    this.displayRows.forEach((p) => {
      p.totalTime = 0; //total the hours
      p.contractTime = 0;
      if (p.currentContract?.contractedHours)
        p.contractTime =
          this.daysShown == 1
            ? p.currentContract.contractedHours / p.currentContract.workDays
            : (p.currentContract.contractedHours / 7) * this.daysShown;
      p.rowHeight = 1;
      if (!p.shifts)
        //probably called out of sequence; no shifts yet - I'll recalc later
        return;
      //go through the shifts
      p.shifts.forEach((s) => {
        //mark wrong department
        s.otherHotel = s.hotel ? s.hotel.id !== currentHotel.id : false;
        s.otherDep =
          s.otherHotel ||
          !this.showDepartments.find(
            (d) =>
              s.departmentName == d.name && s.outletType == d.outletType && s.outletIndex == d.outletIndex && s.subDeptName == d.subDeptName
          );
        if (s.otherHotel) {
          s.departmentLabel = s.hotel.holidexCode;
        } else {
          //figure out the department
          let dep = this.departmentList?.find(
            (d) =>
              d.name == s.departmentName && d.outletType == s.outletType && d.outletIndex == s.outletIndex && s.subDeptName == d.subDeptName
          );
          s.departmentLabel = dep?.label;
        }
        s.rowOffset = 0;
        // pre-parse the start and end so they don't get calculated again ang again
        const start = this.context.isoToContextDate(s.start);
        const end = this.context.isoToContextDate(s.end);
        s.shiftDay = start.startOf('day');
        s.shiftEndDay = end.startOf('day');
        const isShiftInDefaultTime = (start.isAfter(this.timeStart) || start.isSame(this.timeStart)) && start.isBefore(this.timeEnd);

        if (
          isShiftInDefaultTime ||
          (this.showTime === CalendarLength.DAY && // for the DAY view we also display the shifts which wrap over from the previous day
            (end.isAfter(this.timeStart) || end.isSame(this.timeStart)) &&
            (end.isBefore(this.timeEnd) || end.isSame(this.timeEnd)))
        ) {
          let hours = 0;
          if (this.loadCapturedShift) {
            // use capturedShifts
            if (s.capturedShifts[0])
              hours =
                dayjs(s.capturedShifts[0].end).diff(dayjs(s.capturedShifts[0].start), 'minute') / 60 - s.capturedShifts[0].breaks / 60;
          } else hours = end.diff(start, 'minute') / 60 - s.breaks / 60;

          // the shift has to be on the default time in order to be included in the employees totals
          if (isShiftInDefaultTime) {
            p.totalTime += hours * s.quantity * this.includeShiftInTotalsRatio(s, true);
          }
          if (s.otherDep) return; //exclude frsom the rest
          const i = this.displayDateList.findIndex((d) => +d == +s.shiftDay);
          if (i >= 0) {
            if (this.loadCapturedShift && s.capturedShifts[0]) {
              s.capturedShifts[0].locked = this.dateScheduleStatus[i].status === 'APPROVED';
            }
            // exclude leave shifts
            if (this.loadCapturedShift) {
              if (s.capturedShifts[0]) {
                let cHours =
                  dayjs(s.capturedShifts[0].end).diff(dayjs(s.capturedShifts[0].start), 'minute') / 60 - s.capturedShifts[0].breaks / 60;
                this.dateTotals[i] += cHours * s.capturedShifts[0].quantity * this.includeShiftInTotalsRatio(s);
              }
            } else this.dateTotals[i] += hours * s.quantity * this.includeShiftInTotalsRatio(s);
            this.dateShiftsCount[i] += 1;
            if (s.capturedShifts?.length) this.dateShiftsCaptured[i] += 1;
            if (s.capturedShifts && s.capturedShifts[0]?.status == 'SAVED') this.dateShiftsConfirmed[i] += 1;
          }
          // now check for the cell based count
          this.displayDateList.forEach((d, idx) => {
            if (start.add(1, 'minute').isAfter(+d + this.displayCellLength) || end.isBefore(d.add(1, 'minute'))) return; //not inside this zone
            if (this.includeShiftInTotalsRatio(s) == 0) return; //not the sort of shift we add
            // an employee can have more than one shift in an hour so we collect the information here and then add them up later
            // anonymous agency shifts do not have a personnel ID so we use the shift ID to uniquely identify the employee shifts
            const key = s.type === 'AGENCY' && !p.id ? `A${s.id}` : p.id;
            if (key) {
              this.cellShiftsEmployees[idx].set(key, Math.max(this.cellShiftsEmployees[idx].get(key) || 0, s.quantity));
            }
          });
        }
      });
      if (this.showBlocks) {
        //We need to sort out the spacing and row sizes if we are in block mode
        p.shifts.forEach((s, pos) => {
          if (p.isAgency && s.otherDep) return; //don;t stress about agency shifts from other departments
          //go through all previous shifts and count earlier in our day
          for (let i = 0; i < pos; i += 1) {
            if (p.isAgency && p.shifts[i].otherDep) continue; //don;t include other department agency
            if (+s.shiftDay == +p.shifts[i].shiftDay) s.rowOffset += 1;
          }
          if (s.rowOffset >= p.rowHeight) p.rowHeight = s.rowOffset + 1;
        });
        p.shifts = cloneShifts(p.shifts); //required to link the reactivity again
      } //also agency may have to be structured even if we are not in block mode
      else if (p.isAgency) {
        //we need to deal with overlapping shifts to make them all visible
        p.shifts.forEach((s, pos) => {
          if (s.otherDep) return; //only worry about our department
          let rowFilled = [false, false, false, false, false, false, false, false];
          //go through all previous shifts
          for (let i = 0; i < pos; i += 1) {
            let s2 = p.shifts[i];
            if (s2.otherDep) continue;
            if (s.start > s2.end || s.end < s2.start) continue; //no overlap
            rowFilled[s2.rowOffset] = true;
          }
          s.rowOffset = rowFilled.findIndex((v) => !v); //find the first unfilled row and use that
          if (s.rowOffset >= p.rowHeight) p.rowHeight = s.rowOffset + 1;
        });
        p.shifts = cloneShifts(p.shifts); //required to link the reactivity again
      }
    });
    // add up all the employees cells
    this.cellShiftsEmployees.forEach((entry, idx) => {
      this.cellShiftsCount[idx] = Array.from(entry.keys()).reduce((prev, key) => prev + entry.get(key), 0);
    });

    this.integrateCheckInData();
    this.rowCheckChanged(null);
  }

  /*
  includeShiftInTotals(s, isEmployeeTotal = false) {
    if (s.type == 'OFF') return false;
    if (s.type == 'ADMIN') return false;
    if (s.type == 'TRAINING') return isEmployeeTotal;
    if (s.type == 'ABSENCE') {
      if (isEmployeeTotal) {
        if (['LEAVE_WITHOUT_PAY', 'UNPAID_LEAVE'].includes(s.absenceType)) return false;
      } else {
        return false;
      }
    }
    if (s.capturedShifts && s.capturedShifts[0]) {
      if (s.capturedShifts[0].cancel) return false;
    }
    return true;
  }*/

  includeShiftInTotalsRatio(s, isEmployeeTotal = false) {
    if (s.capturedShifts && s.capturedShifts[0]) {
      if (s.capturedShifts[0].cancel) return 0;
      if (s.capturedShifts[0].absenceType === 'NO_SHOW') return 0;
    }
    if (s.type == 'OFF') return 0;
    if (s.type == 'ADMIN') return isEmployeeTotal ? 1 : 0;
    if (s.type == 'TRAINING') return isEmployeeTotal ? 1 : 0;
    if (s.type == 'APPRENTICE') return isEmployeeTotal ? 1 : this.context.getSystemConfig().SHIFT_APPRENTICE_PRODUCTIVITY;
    if (s.type == 'ABSENCE') {
      if (isEmployeeTotal) {
        if (['LEAVE_WITHOUT_PAY', 'UNPAID_LEAVE'].includes(s.absenceType)) return 0;
      } else {
        return 0;
      }
    }
    return 1;
  }

  makeEventStructure(menu: any, event: any, initcell: number, person: any = null, shift = null, forceCopy = false, oldperson = null) {
    let allDays = false;
    if (initcell < 0 || typeof initcell !== 'number') {
      initcell = 0;
      allDays = true;
    }
    if (this.showTime === CalendarLength.DAY) initcell = 0;
    let sd = this.timeStart.add(initcell, 'days');
    let startDate = sd.toISOString();

    let pe = this.timeStart.add(this.daysShown - 1, 'days');

    //Get a list of shifts
    let colShifts = [];
    let colSelShifts = [];
    this.displayRows.forEach((p) => {
      if (p?.shifts?.length) {
        let shifts = p.shifts
          .filter((s) => !s.otherDep && (allDays || +sd == +s.shiftDay))
          .map((s) => ({
            person: p,
            ...s,
          }));
        colShifts = colShifts.concat(shifts);
        if (p.rowChecked) colSelShifts = colSelShifts.concat(shifts);
      }
    });

    const scheduleStatus = this.dateScheduleStatus[initcell];
    if (!scheduleStatus) {
      this.sentry.sendMessage('Unable to retrieve schedule status', 'warning', {
        initcell,
        dateScheduleStatus: JSON.stringify(this.dateScheduleStatus),
        menu: !!menu,
        event: !!event,
        person: !!person,
        shift: !!shift,
        forceCopy,
        oldperson: !!oldperson,
      });
    }

    return {
      person,
      oldperson,
      shift,
      hotel: this.context.getCurrentBasicHotel(),
      scheduleId: scheduleStatus?.scheduleId,
      scheduleDay: scheduleStatus?.day || startDate,
      colStatus: scheduleStatus?.status,
      allColStatus: this.dateScheduleStatus?.map((c) => c && c.status) || [],
      persons: this.displayRows.filter((r) => r.rowChecked),
      allPersons: this.displayRows,
      shifts: colSelShifts,
      allShifts: colShifts,
      department: this.showDepartment,
      departments: this.useMultDeps ? this.showDepartments : null,
      startDate,
      periodStart: this.timeStart,
      periodEnd: pe.toDate(),
      daysShown: this.daysShown,
      showMenu: (m) => {
        this.addOptions = m;
        this.dropRight = initcell > this.displayCells * 0.6;
        menu.toggle(event);
      },
      refresh: () => this.calculateAll(),
      reload: () => this.loadData(),
      forceCopy,
    };
  }

  shiftClick(menu, event, initcell, person, shift) {
    this.shiftClicked.emit(this.makeEventStructure(menu, event, initcell, person, shift));
  }

  addCell(menu: any, event: any, person: any, initcell: number) {
    if (this.showTime === CalendarLength.DAY) initcell = 0;
    if (typeof initcell !== 'number') {
      initcell = 0;
    }
    let startDate = dayjs(+this.timeStart)
      .add(initcell, 'days')
      .toDate();
    this.cellAddClicked.emit(this.makeEventStructure(menu, event, initcell, person));
  }

  addCol(menu: any, event: any, initcell: number) {
    this.colAddClicked.emit(this.makeEventStructure(menu, event, initcell));
  }

  saveCol(menu: any, event: any, initcell: number) {
    this.colSaveClicked.emit(this.makeEventStructure(menu, event, initcell));
  }

  approveCol(menu: any, event: any, initcell: number) {
    this.colApproveClicked.emit(this.makeEventStructure(menu, event, initcell));
  }

  unapproveCol(menu: any, event: any, initcell: number) {
    this.colUnapproveClicked.emit(this.makeEventStructure(menu, event, initcell));
  }

  public setChecks(ev) {
    let newV = ev.checked;
    this.displayRows.forEach((r) => {
      r.rowChecked = r.isAgency ? false : newV;
    });
    this.calcCheckCount();
  }

  rowCheckChanged(row: any) {
    this.checkAll = this.displayRows.every((r) => r.rowChecked || r.isAgency);
    this.calcCheckCount();
  }

  calcCheckCount() {
    this.checkedCount = this.displayRows.reduce((a, r) => (a += r.rowChecked ? 1 : 0), 0);
  }

  export(menu, ev) {
    this.exportClicked.emit(this.makeEventStructure(menu, ev, 0));
  }

  doExtraCommand() {
    this.extraCommandClicked.emit(this.makeEventStructure(null, null, -1));
  }

  doExtraCommand2() {
    this.extraCommandClicked2.emit(this.makeEventStructure(null, null, -1));
  }

  goBack() {
    this.router.navigate([this.backRoute]);
  }

  getDebugInfo(scheduleStatus: IScheduleStatus) {
    return this.log.logLevel === 2 ? `\n<<debug info>>\nID ${scheduleStatus.scheduleId}\nDAY ${scheduleStatus.day}` : '';
  }

  saveToolTip(i) {
    const scheduleStatus = this.dateScheduleStatus[i];
    if (this.loadCapturedShift)
      return (
        (scheduleStatus.status == 'AWAITING'
          ? 'not submitted - click to submit remaining shifts'
          : `manually submitted on ${dayjs(scheduleStatus.captureSubmitDate).format('D MMM')}`) + this.getDebugInfo(scheduleStatus)
      );
    else
      return (
        (scheduleStatus.status == 'AWAITING'
          ? 'not submitted - click to submit shifts to GM'
          : scheduleStatus.missedSubmit
          ? `not submitted - deadline passed ${
              scheduleStatus.scheduleAutoDate ? 'on ' + dayjs(scheduleStatus.scheduleAutoDate).format('D MMM') : ''
            }`
          : `manually submitted to GM on ${dayjs(scheduleStatus.scheduleSubmitDate).format('D MMM')}`) + this.getDebugInfo(scheduleStatus)
      );
  }

  submittedToolTip(i) {
    const scheduleStatus = this.dateScheduleStatus[i];
    if (this.loadCapturedShift) {
      return (
        `${scheduleStatus.captureSubmitDate ? 'manually ' : ''}submitted ${dayjs(
          scheduleStatus.captureSubmitDate || scheduleStatus.captureAutoDate
        ).format('D MMM')} - click to lock` + this.getDebugInfo(scheduleStatus)
      );
    } else {
      return (
        `${scheduleStatus.scheduleSubmitDate ? 'manually ' : ''}submitted ${dayjs(
          scheduleStatus.scheduleSubmitDate || scheduleStatus.scheduleAutoDate
        ).format('D MMM')} - click to approve` + this.getDebugInfo(scheduleStatus)
      );
    }
  }

  approveToolTip(i) {
    const scheduleStatus = this.dateScheduleStatus[i];
    if (this.loadCapturedShift) {
      return (
        `${scheduleStatus.captureApproveDate ? 'manually ' : ''}locked on ${dayjs(
          scheduleStatus.captureApproveDate || scheduleStatus.captureAutoDate
        ).format('D MMM')}` + this.getDebugInfo(scheduleStatus)
      );
    } else if (scheduleStatus.missedApprove) {
      return `system auto-approved on ${dayjs(scheduleStatus.scheduleAutoDate).format('D MMM')}` + this.getDebugInfo(scheduleStatus);
    } else {
      return `manually approved on ${dayjs(scheduleStatus.scheduleApproveDate).format('D MMM')}` + this.getDebugInfo(scheduleStatus);
    }
  }

  dragShift(rowData, shift, e) {
    this.draggedShift = shift;
    this.draggedRow = rowData;
  }

  dropShift(rowData, i, e) {
    this.shiftMoved.emit(
      this.makeEventStructure(null, e, i, rowData, this.draggedShift, e.ctrlKey || e.shiftKey || e.metaKey || e.altKey, this.draggedRow)
    );
  }
}
