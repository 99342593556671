import { Component, OnInit, Output, Input, EventEmitter, forwardRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';

export interface ITableDefinition {
  cols: Array<{
    value: string;
    label: string;
    sort?: boolean;
    filter: 'text' | string;
  }>;
  heading?: string;
  showAdd?: boolean;
  addLabel?: string;
  helpfiles?: any[];
  colFilters?: boolean;
}

/**
 * This component shows a data table with stripes, sorts and filters
 */
@Component({
  selector: 'app-display-table',
  styleUrls: ['./display-table.component.scss'],
  templateUrl: './display-table.component.html',
})
export class DisplayTableComponent implements OnInit {
  @Input() paginator: boolean;
  @Input() showCurrentPageReport: boolean;
  @Input() pageRows: number;
  @Input() value: any[];
  @Input() def: any;
  @Input() isLoading: boolean = false;
  @Input() rowsPerPageOptions: [] = null;

  @Output() add = new EventEmitter<number>();
  @Output() rowSelected = new EventEmitter<number>();

  @ViewChild('dt', { static: true }) dataTable;

  constructor() {}

  ngOnInit() {
    //go through the filter and implement any defaults
    /*if (this.def.colFilters)
      for (var col of this.def.cols) {
        if (col.defaultFilter)
          this.dataTable.filter(col.defaultFilter,col.value,'contains');
      }*/
  }

  addAction() {
    this.add.next(0);
  }

  filterTbl(value: string, col: string, type: string) {
    this.dataTable.filter(value, col, type);
  }

  selectAction(event) {
    this.rowSelected.next(event.data);
  }
}
