import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { getAbsenceTypeLookUp, getShiftTypeLookUp } from 'src/commontypes/shifts';
import { ContextService } from 'src/services/context.service';
import { Clipboard } from '@angular/cdk/clipboard';

import dayjs from 'dayjs';
import { IScheduleStatus } from '../shift-display/shift-display.component';
import { LoggingService } from 'src/services/logging.service';

const line = '--------------------------------------------';
@Component({
  selector: 'app-shift-marker',
  styleUrls: ['./shift-marker.component.scss'],
  templateUrl: './shift-marker.component.html',
})
export class ShiftMarkerComponent implements OnInit {
  @Input() shift;
  @Input() dateScheduleStatus: IScheduleStatus;
  @Input() timeStart = 0;
  @Input() cellStart = 0;
  @Input() timeEnd = 0;
  @Input() timeWidth = 1;
  @Input() showFullDetail = true;
  @Input() showAsBlock = false;
  @Input() showCaptured = false;
  @Input() showDepartment = false;

  @Output() sclick = new EventEmitter<void>();

  public startPos = 10;
  public width = 100;
  public start: dayjs.Dayjs;
  public end: dayjs.Dayjs;
  public cStart: dayjs.Dayjs;
  public cEnd: dayjs.Dayjs;
  public cType: string;
  public cLabel: string;
  public cQty = 0;
  public showLeft = true;
  public showRight = true;
  public topOffset = 0;
  public isCaptured = false;
  public isAbsence = false;
  public isChanged = false;
  public isOff = false;
  public isCapturedImperfect = false;
  public isStartImperfect = false;
  public isEndImperfect = false;

  public inDisplay = true;
  public isCancelled = false;
  public isPM = false;
  public isPreDraft = false;

  public types = getShiftTypeLookUp();
  public aTypes = getAbsenceTypeLookUp();
  public shortCode = '';
  public cShortCode = '';
  public cComment = null;
  public finalShortType = 'UNKNOWN';

  constructor(private context: ContextService, private log: LoggingService, private clipboard: Clipboard) {}

  ngOnInit() {}

  clicked() {
    this.sclick.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.start = this.context.isoToContextDate(this.shift.start);
    this.end = this.context.isoToContextDate(this.shift.end);
    this.cStart = null;
    this.cEnd = null;

    let astart = +this.start;
    let aend = +this.end;

    this.inDisplay = aend > +this.timeStart && astart < +this.timeEnd;
    this.showLeft = this.showRight = true;

    if (aend > +this.timeEnd) {
      aend = +this.timeEnd;
      // this shift ends after the end of the current display so if we're showing
      // this in block display mode then it is fine to add the end time
      // in non-block display mode the time might be confusing since it falls into the next day
      // so we don't display it
      this.showRight = !!this.showAsBlock;
    }
    if (astart < +this.timeStart) {
      // this shift starts before the beginning of the current display so we use a similar
      // strategy to determine what we display as with the case above
      this.showLeft = !!this.showAsBlock;
      astart = +this.timeStart;
    }

    this.startPos = ((+astart - this.cellStart) * 100) / this.timeWidth;
    this.width = ((+aend - +astart) * 100) / this.timeWidth;
    if (this.showAsBlock) this.topOffset = 0.5 + this.shift.rowOffset * 2;
    else this.topOffset = 0.5 + this.shift.rowOffset * 4;

    this.isCaptured = false;
    this.isAbsence = false;
    this.isCancelled = false;
    this.isCapturedImperfect = false;
    this.isOff = this.shift.type === 'OFF' || this.shift.type == 'ADMIN';
    this.shortCode = this.types[this.shift.type].short;

    if (this.shift.type == 'ABSENCE') {
      this.shortCode = this.aTypes[this.shift.absenceType].short;
    }

    this.finalShortType = this.shortCode;

    if (this.showCaptured) {
      if (this.shift.capturedShifts?.length) {
        this.isCaptured = true;
        this.isPreDraft = this.shift.capturedShifts[0].status == 'PRE_DRAFT';
        this.isCancelled = !!this.shift.capturedShifts[0].cancel;
        this.cStart = this.context.isoToContextDate(this.shift.capturedShifts[0].start);
        this.cEnd = this.context.isoToContextDate(this.shift.capturedShifts[0].end);
        this.cQty = this.shift.capturedShifts[0].quantity;
        this.cComment = this.shift.capturedShifts[0]?.importComment;
        this.isAbsence = this.shift.capturedShifts[0].absence;
        if (this.isAbsence) this.cType = this.aTypes[this.shift.capturedShifts[0].absenceType]?.short;
        else this.cType = this.shortCode;
        this.isStartImperfect = this.shift.start != this.shift.capturedShifts[0].start;
        this.isEndImperfect = this.shift.end != this.shift.capturedShifts[0].end;
        this.isCapturedImperfect =
          this.isStartImperfect || this.isEndImperfect || this.shift.breaks != this.shift.capturedShifts[0]?.breaks;
        this.finalShortType = this.isCancelled ? 'CL' : this.cType;
      }
    } else {
      //schedule view
      this.isPM = this.shift.postApprovalUpdate;
    }
    this.isChanged = this.isAbsence || this.isCancelled;
  }

  onRightClick() {
    this.clipboard.copy(JSON.stringify(this.toolTipText()));
    return true; // allow the default context menu to show
  }

  toolTipText() {
    let shiftDep = this.shift.otherDep || this.showDepartment ? ' - ' + this.shift.departmentLabel : '';
    //need to calculate the length in hours
    let tmins = (+this.end - +this.start) / 1000 / 60 - this.shift.breaks;
    let hrs = Math.floor(tmins / 60);
    let mins = Math.floor(tmins % 60);
    let lentxt = ' - ' + hrs + ' hours' + (mins ? ' ' + mins + ' mins' : '');

    const getDate = (isoDate?: string) => isoDate?.split('T')?.[0] || null;
    const debugText =
      this.log.logLevel === 2
        ? `\n${line}\n<<debug info>>\nshiftId ${this.shift?.id} actualId ${
            this.shift?.capturedShifts?.[0].id || null
          }\nshiftCreated ${getDate(this.shift?.createdAt)} actualCreated ${getDate(this.shift?.capturedShifts?.[0]?.createdAt)}`
        : '';

    const cancelText = this.isCancelled ? '\nCANCELLED' : '';

    if (this.showCaptured) {
      let sttxt = 'SUBMISSION REQUIRED';
      if (this.dateScheduleStatus.status == 'SUBMITTED')
        sttxt = `SUBMITTED: ${dayjs(this.dateScheduleStatus.captureSubmitDate).format('D MMM')}`;
      if (this.dateScheduleStatus.status == 'APPROVED')
        sttxt = `LOCKED: ${dayjs(this.dateScheduleStatus.captureApproveDate).format('D MMM')}`;
      let t =
        this.types[this.shift.type].label +
        ' shift ' +
        shiftDep +
        lentxt +
        '\n' +
        ' Scheduled ' +
        this.start.format('DD MMM HH:mm') +
        ' - ' +
        this.end.format('HH:mm') +
        (this.isOff ? '' : '\n Break - ' + this.shift.breaks + ' mins') +
        `\n${line}\n` +
        sttxt;
      if (this.isCaptured)
        t +=
          '\n\n Actual ' +
          this.cStart.format('DD MMM HH:mm') +
          ' - ' +
          this.cEnd.format('HH:mm') +
          (this.isOff ? '' : '\n Break - ' + this.shift.capturedShifts[0].breaks + ' mins');
      t += `\nLAST UPDATED: ${dayjs(this.shift.updatedAt).format('D MMM')}`;
      t += this.shift.comments || (this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment) ? `\n${line}` : '';
      t += this.shift.comments ? `\nSHIFT COMMENTS:\n${this.shift.comments}` : '';
      t +=
        this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment
          ? `\nABSENCE COMMENTS:\n${this.shift?.capturedShifts[0]?.absenceComment}`
          : '';
      if (this.cComment) t += `\n${this.cComment}\n`;
      return t + cancelText + debugText;
    } else {
      //and figure out some status data
      let sttxt = `SAVING REQUIRED`;
      if (this.dateScheduleStatus.status == 'SUBMITTED')
        sttxt = `SAVED:\n${dayjs(this.dateScheduleStatus.scheduleSubmitDate).format('D MMM')}`;
      if (this.dateScheduleStatus.status == 'APPROVED')
        sttxt = `APPROVED BY GM: ${dayjs(this.dateScheduleStatus.scheduleApproveDate).format('D MMM')}`;
      if (this.dateScheduleStatus.status == 'AUTO_APPROVED')
        sttxt = `SYS AUTO-APPROVE: ${dayjs(this.dateScheduleStatus.scheduleAutoDate).format('D MMM')}`;
      if (this.isPM) sttxt = 'CHANGED AFTER GM APPROVAL';

      if (this.isAbsence) {
        return (
          `Absent (${this.cLabel})` +
          shiftDep +
          lentxt +
          '\n' +
          'Scheduled ' +
          this.start.format('DD MMM HH:mm') +
          ' - ' +
          this.end.format('HH:mm') +
          `\n${line}\n` +
          sttxt +
          `\nLAST UPDATED: ${dayjs(this.shift.updatedAt).format('D MMM')}` +
          (this.shift.comments || (this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment) ? `\n${line}` : '') +
          (this.shift.comments ? `\nSHIFT COMMENTS:\n${this.shift.comments}` : '') +
          (this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment
            ? `\nABSENCE COMMENTS:\n${this.shift?.capturedShifts[0]?.absenceComment}`
            : '') +
          cancelText +
          debugText
        );
      }
      return (
        this.types[this.shift.type].label +
        ' shift ' +
        shiftDep +
        lentxt +
        '\n' +
        'Scheduled ' +
        this.start.format('DD MMM HH:mm') +
        ' - ' +
        this.end.format('HH:mm') +
        (this.isOff ? '' : '\n Break - ' + this.shift.breaks + ' mins') +
        `\n${line}\n` +
        sttxt +
        `\nLAST UPDATED: ${dayjs(this.shift.updatedAt).format('D MMM')}` +
        (this.shift.comments || (this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment) ? `\n${line}` : '') +
        (this.shift.comments ? `\nSHIFT COMMENTS:\n${this.shift.comments}` : '') +
        (this.shift?.capturedShifts && this.shift?.capturedShifts[0]?.absenceComment
          ? `\nABSENCE COMMENTS:\n${this.shift?.capturedShifts[0]?.absenceComment}`
          : '') +
        cancelText +
        debugText
      );
    }
  }

  isCapturedLocked() {
    return this.shift.capturedShifts && this.shift.capturedShifts[0] && this.shift.capturedShifts[0].locked;
  }
}
