<app-display-table
  [value]="persons"
  [def]="tblDef"
  [isLoading]="loading"
  (add)="addRow()"
  (rowSelected)="editRow($event)"
  [paginator]="persons?.length > 50"
  [pageRows]="50"
  [showCurrentPageReport]="true"
>
</app-display-table>
<ng-container *ngIf="editPerson">
  <p-dialog header="Employee Details" [(visible)]="editPerson" closable="false" responsive showEffect="fade" modal="true">
    <div class="content">
      <div class="p-formgrid p-grid">
        <div class="p-field p-col">
          <label
            >Time and attendance ID (T&A ID)
            <input
              type="text"
              pInputText
              [(ngModel)]="editPerson.personnelNo"
              #personnelNo="ngModel"
              required
              [disabled]="!canEditEmployee || savingEdit"
          /></label>
          <p-message
            *ngIf="editPerson.isHomeHotel && !employeeValid()"
            severity="error"
            text="Must start with Holidex and be at least 8 characters long and contain no spaces"
          ></p-message>
        </div>
        <div class="p-field p-col">
          <label
            >Check In ID (Merlin ID)
            <input
              type="text"
              pInputText
              [(ngModel)]="editPerson.checkInId"
              #checkInId="ngModel"
              [disabled]="!canEditEmployee || savingEdit"
              [change]="checkInIdChanged()"
          /></label>
          <p-message
            *ngIf="editPerson.isHomeHotel && !checkInIdValid"
            severity="error"
            [text]="
              'Check in ID must be between ' +
              minCheckinLen +
              ' and ' +
              maxCheckinLen +
              'alphanumeric characters and should not exist on this hotel'
            "
          ></p-message>
        </div>
      </div>
      <div class="p-formgrid p-grid">
        <div class="p-field p-col">
          <label
            >First Name
            <input
              type="text"
              pInputText
              [(ngModel)]="editPerson.firstName"
              #firstname="ngModel"
              required
              [disabled]="!canEditEmployee || savingEdit"
          /></label>
          <p-message *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" severity="error" text="Required"></p-message>
        </div>
        <div class="p-field p-col">
          <label
            >Last Name
            <input
              type="text"
              pInputText
              [(ngModel)]="editPerson.lastName"
              #lastname="ngModel"
              required
              [disabled]="!canEditEmployee || savingEdit"
          /></label>
          <p-message *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" severity="error" text="Required"></p-message>
        </div>
      </div>

      <div class="p-formgrid p-grid">
        <div class="p-field p-col" *ngIf="currentHotel.holidayConfig?.type === 'HOURS'">
          <label
            >Annual Leave Entitlement
            <p-inputNumber
              size="3"
              min="0"
              [(ngModel)]="editPerson.holidayHours"
              required
              #holidayHoursCtrl="ngModel"
              [disabled]="!canEditEmployee || savingEdit"
            ></p-inputNumber>
          </label>
          <label style="margin-left: 0.5em"> working hours per year</label>
          <p-message
            *ngIf="holidayHoursCtrl.invalid && (holidayHoursCtrl.dirty || holidayHoursCtrl.touched)"
            severity="error"
            text="Required"
          ></p-message>
        </div>
        <div class="p-field p-col" *ngIf="currentHotel.holidayConfig?.type !== 'HOURS'">
          <label
            >Annual Leave Entitlement
            <p-inputNumber
              size="2"
              min="0"
              max="60"
              [(ngModel)]="editPerson.holidayDays"
              required
              #holidayDaysCtrl="ngModel"
              [disabled]="!canEditEmployee || savingEdit"
            ></p-inputNumber>
          </label>
          <label style="margin-left: 0.5em"> working days per year</label>
          <p-message
            *ngIf="holidayDaysCtrl.invalid && (holidayDaysCtrl.dirty || holidayDaysCtrl.touched)"
            severity="error"
            text="Required"
          ></p-message>
        </div>
        <div class="p-field p-col" *ngIf="currentHotel.holidayConfig?.type === 'HOURS'">
          <label
            >Public Holiday Entitlement
            <p-inputNumber
              size="2"
              min="0"
              max="50"
              [(ngModel)]="editPerson.publicHolidayHours"
              required
              #publicHolidayHoursCtrl="ngModel"
              [disabled]="!canEditEmployee || savingEdit"
            ></p-inputNumber>
          </label>
          <label style="margin-left: 0.5em"> hours per year</label>
          <p-message
            *ngIf="publicHolidayHoursCtrl.invalid && (publicHolidayHoursCtrl.dirty || publicHolidayHoursCtrl.touched)"
            severity="error"
            text="Required"
          ></p-message>
        </div>
        <div class="p-field p-col" *ngIf="currentHotel.holidayConfig?.type !== 'HOURS'">
          <label
            >Public Holiday Entitlement
            <p-inputNumber
              size="2"
              min="0"
              max="50"
              [(ngModel)]="editPerson.publicHolidayDays"
              required
              #publicHolidayCtrl="ngModel"
              [disabled]="!canEditEmployee || savingEdit"
            ></p-inputNumber>
          </label>
          <label style="margin-left: 0.5em"> days per year</label>
          <p-message
            *ngIf="publicHolidayCtrl.invalid && (publicHolidayCtrl.dirty || publicHolidayCtrl.touched)"
            severity="error"
            text="Required"
          ></p-message>
        </div>
      </div>
      <div class="p-formgrid p-grid" *ngIf="canViewHolidays">
        <div class="p-field p-col">
          <label
            [pTooltip]="
              'Public (' + (editPerson.publicHolidaysTaken || 0) + ') and annual (' + (editPerson.annualHolidaysTaken || 0) + ') leave'
            "
            tooltipPosition="bottom"
          >
            Leave Taken:
            <ng-container *ngIf="editPerson.holidayStart?.length"
              >{{ (editPerson.annualHolidaysTaken || 0) + (editPerson.publicHolidaysTaken || 0) }}
              {{ editPerson.holidayType || currentHotel.holidayConfig?.type?.toLowerCase() }} since
              {{ editPerson.holidayStart }}
              <ng-container *ngIf="editPerson.holidaysUpdatedAt || editPerson.lastHoliday">
                (<span style="font-style: italic" *ngIf="editPerson.lastHoliday">last leave processed {{ editPerson.lastHoliday }}</span
                ><span style="font-style: italic" *ngIf="editPerson.holidaysUpdatedAt && !editPerson.lastHoliday"
                  >updated {{ editPerson.holidaysUpdatedAt | date: 'YYYY-MM-dd' }}</span
                >)</ng-container
              ></ng-container
            >
            <ng-container *ngIf="!editPerson.holidayStart?.length">no leave statistics available</ng-container>
          </label>
        </div>
      </div>

      <div class="p-formgrid p-grid">
        <div class="p-field p-col-3" *ngIf="editPerson.agency || !editPerson.id">
          <span
            [pTooltip]="
              editPerson.id
                ? 'To make this employee non-agency first terminate their contract and then create a new employee'
                : 'Once saved this value cannot be changed'
            "
          >
            <label
              >Agency Employee
              <p-checkbox
                binary="true"
                [(ngModel)]="editPerson.agency"
                [disabled]="!canEditEmployee || savingEdit || editPerson.id"
              ></p-checkbox> </label
          ></span>
        </div>
        <div class="p-field p-col-4">
          <label
            >Leaving/contract terminated
            <p-checkbox binary="true" [(ngModel)]="editPerson.leaving" [disabled]="!canEditEmployee || savingEdit"></p-checkbox>
          </label>
        </div>
        <div class="p-field p-col" *ngIf="editPerson.leaving">
          <label
            >Termination Date
            <p-calendar
              [(ngModel)]="editPerson.leavingDate"
              dateFormat="d/m/yy"
              [showIcon]="true"
              appendTo="body"
              [disabled]="!canEditEmployee || savingEdit"
            ></p-calendar>
          </label>
          <p-message *ngIf="+editPerson.leavingDate === 0" severity="error" text="Need to set termination date"></p-message>
        </div>
      </div>

      <p-panel>
        <ng-template pTemplate="header">
          <label>Contracts (where the employee is scheduled)</label
          ><p-message *ngIf="!editPerson.allContractsValid" severity="error" text="Incomplete."></p-message>
          <button
            type="button"
            pButton
            icon="pi pi-plus"
            (click)="addContract()"
            label="Add"
            class="p-ml-auto p-button-sm"
            *ngIf="canEditEmployee"
            [disabled]="savingEdit"
          ></button>
        </ng-template>
        <ng-container *ngFor="let contract of contracts">
          <app-manning-contract
            *ngIf="!contract.deleteMe"
            [contract]="contract"
            (onChange)="contractsChanged()"
            (onDelete)="contractRemoved($event)"
            [disabled]="!canEditEmployee || !editPerson.isHomeHotel || savingEdit"
            [cluster]="cluster"
            [homeHotelId]="homeHotel"
            [agency]="editPerson.agency"
            [currency]="currentHotel?.localCurrency"
          >
          </app-manning-contract>
          <p-message *ngIf="!contract.isOpen && contract.overlap" severity="error" text="Overlapping contract"></p-message>
        </ng-container>
      </p-panel>
      <br />
      <p-panel *ngIf="!editPerson.agency">
        <ng-template pTemplate="header">
          <label>System access (what the employee has access to at {{ currentHotel?.holidexCode }})</label>
          <p-selectButton
            [options]="accountOptions"
            [(ngModel)]="useAccount"
            optionLabel="label"
            optionValue="value"
            class="p-ml-auto p-button-sm"
            [disabled]="!canEditUser || savingEdit"
          ></p-selectButton>
        </ng-template>
        <p-messages *ngIf="!editPerson.isHomeHotel" severity="warn">
          <ng-template pTemplate>
            <div>
              This is not the employee's home hotel hotel, you will only be able to edit the departments they can access. <br />Please
              change to
              <a (click)="switchHotel(editPerson.hotelId)" style="cursor: pointer; text-decoration: underline">{{
                editPerson.holidexCode
              }}</a>
              to edit the other access details.
            </div>
          </ng-template>
        </p-messages>
        <app-account
          *ngIf="useAccount && canViewUser"
          [(account)]="account"
          (isValid)="accountValidityChanged($event)"
          [disabled]="!canEditUser || savingEdit"
          [disableDepartments]="!canEditUser"
          [hotel]="currentHotel"
          [cluster]="cluster"
        ></app-account>
      </p-panel>
    </div>
    <p-footer>
      <ng-container *ngIf="canEditEmployee">
        <button
          type="button"
          pButton
          icon="pi pi-minus"
          (click)="removePerson()"
          label="Remove"
          [disabled]="savingEdit || (editPerson?.leaving && !editPerson?.leavingDate)"
          class="p-button-danger"
        ></button>
      </ng-container>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="cancelPerson()"
        label="Cancel"
        [disabled]="savingEdit"
        class="p-button-secondary"
      ></button>
      <button
        *ngIf="canEditEmployee || useAccount"
        type="button"
        pButton
        [icon]="savingEdit ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (click)="savePerson()"
        label="Save"
        [disabled]="
          savingEdit ||
          firstname.invalid ||
          lastname.invalid ||
          !editPerson.personnelNo ||
          !employeeValid() ||
          !editPerson.allContractsValid ||
          (canEditUser && useAccount && !accountValid) ||
          !checkInIdValid ||
          loadingCheckInId
        "
      ></button>
    </p-footer>
  </p-dialog>
</ng-container>
