<ng-container *ngIf="shift">
  <p-dialog
    [header]="'Shift ' + (shift.departments ? 'Information' : shift.departmentLabel)"
    [visible]="shift"
    showEffect="fade"
    modal="true"
    [closable]="false"
  >
    <div class="content">
      <div class="p-grid">
        <div class="p-col-12" *ngIf="shift.departments">
          <div class="p-field" style="margin-bottom: -1em">
            <label
              >Department
              <p-dropdown
                [options]="departmentList"
                [(ngModel)]="selectedDepartment"
                (ngModelChange)="departmentChanged($event)"
                optionLabel="label"
                optionValue="code"
                appendTo="body"
              >
              </p-dropdown>
            </label>
          </div>
        </div>
        <div class="p-col-12">
          <div class="p-d-flex p-jc-between">
            <div>
              <ng-container *ngIf="shift.detail.type != 'AGENCY'">
                <div>
                  <label>Employee: {{ shift.personLabel }}</label>
                </div>
                <br />
                <div class="p-field">
                  <label
                    >Type
                    <p-dropdown
                      [options]="typeCodes"
                      [(ngModel)]="shift.detail.type"
                      optionLabel="label"
                      optionValue="code"
                      placeholder="select type"
                      appendTo="body"
                      (onChange)="typeCodeChanged($event)"
                    >
                    </p-dropdown>
                  </label>
                </div>
                <div class="p-field" *ngIf="shift.detail.type === 'ABSENCE'">
                  <label
                    >Absence
                    <p-dropdown
                      [options]="absenceCodes"
                      [(ngModel)]="shift.detail.absenceType"
                      optionLabel="label"
                      optionValue="code"
                      placeholder="select absence"
                      appendTo="body"
                    >
                    </p-dropdown>
                  </label>
                </div>
              </ng-container>
              <ng-container *ngIf="shift.detail.type == 'AGENCY'">
                <div>
                  <label>Agency Shift</label>
                </div>
                <br />
                <div class="p-field">
                  <label
                    >Number of Agency Members
                    <p-inputNumber
                      min="1"
                      max="1000"
                      size="4"
                      maxlength="4"
                      mode="decimal"
                      [(ngModel)]="shift.detail.quantity"
                      [disabled]="shift.rows[0].agency"
                    ></p-inputNumber>
                  </label>
                  <p-message
                    styleClass="warning-message"
                    *ngIf="!shift.detail.quantity"
                    severity="error"
                    text="should be non-zero"
                  ></p-message>
                </div>
              </ng-container>
            </div>
            <div class="meta" *ngIf="shift.detail?.createdAt || shift.detail?.updatedAt">
              <ng-container *ngIf="shift.detail.createdAt">
                <label>Created: </label><br />
                {{ shift.detail.createdAt | date: 'YYYY-MM-dd HH:mm' }}
                <br
              /></ng-container>
              <ng-container *ngIf="shift.detail.updatedAt">
                <label>Last updated: </label><br />{{ shift.detail.updatedAt | date: 'YYYY-MM-dd HH:mm' }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <app-time-range [(startDateString)]="shift.detail.start" [(endDateString)]="shift.detail.end"></app-time-range>
        <p-message styleClass="warning-message" *ngIf="!isValidTimeRange()" severity="error" text="invalid scheduled hours"></p-message>
      </div>
      <br />
      <div class="p-field" *ngIf="hasBreaks()">
        <label
          >Breaks
          <p-inputNumber [min]="0" [max]="120" [size]="3" [maxlength]="3" mode="decimal" [(ngModel)]="shift.detail.breaks"></p-inputNumber>
        </label>
        <label style="margin-left: 0.25rem"> mins </label>
      </div>
      <label>Comments</label>
      <div>
        <textarea [rows]="5" maxlength="1024" pInputTextarea [(ngModel)]="shift.detail.comments"></textarea>
      </div>
    </div>
    <p-footer>
      <button type="button" pButton icon="pi pi-minus" (click)="removeEdit()" label="Remove" class="p-button-danger"></button>
      <button type="button" pButton icon="pi pi-times" (click)="cancelEdit()" label="Cancel" class="p-button-secondary"></button>
      <button type="button" pButton icon="pi pi-check" [disabled]="!isValid()" (click)="saveEdit()" label="Save"></button>
    </p-footer>
  </p-dialog>
</ng-container>
